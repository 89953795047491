import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { useUser } from "../Logic/Firebase/Auth";

const AccountProfile = () => {
  const user = useUser();
  return (
    <>
      {user && user.photoURL ? (
        <Avatar alt={user.displayName} src={user.photoURL}></Avatar>
      ) : (
        <Avatar alt={"user.displayName"}></Avatar>
      )}
    </>
  );
};

export default AccountProfile;
