import { Switch, Route } from "react-router-dom";
import { Redirect } from "react-router";
import Introduction from "../Screens/Introduction";
import Home from "../Screens/Home/Home";
import User from "../Screens/User/User";
import About from "../Screens/About";
import Support from "../Screens/Support";
import TermsAndConditions from "../Screens/TermsAndConditions";
import Chat from "../Screens/Chat/Chat";
import Empty from "../Screens/Empty";
import { useUser } from "../Logic/Firebase/Auth";
import Loading from "../Components/LoadingScreen";
import NotFound from "../Screens/NotFound";
import ResetPassword from "../Screens/ResetPassword";
import { confirmEmail } from "../Logic/Firebase/Auth";
import { useEffect } from "react";

const Routes = () => {
  const RedirectToHome = function () {
    return <Redirect to="/Home" />;
  };
  const RedirectToUser = function () {
    return <Redirect to="/user" />;
  };
  useEffect(() => {
    async function check() {
      let data = await confirmEmail();
      return data;
    }
    check();
  }, []);
  const user = useUser();
  return user === undefined ? (
    <Loading />
  ) : user !== null ? (
    <Switch>
      <Route path="/" key="/init1" exact strict component={RedirectToUser} />
      <Route path="/user" key="/user1" exact strict component={User} />
      <Route
        path="/Home"
        key="/Home2"
        exact
        strict
        component={RedirectToUser}
      />

      <Route
        path="/Chat"
        key="/Chat1"
        exact
        strict
        component={Chat}
        isPrivate
      />
      <Route path="/About" exact strict component={About} isPrivate />
      <Route path="/Support" exact strict component={Support} isPrivate />
      <Route path="/TC" exact strict component={TermsAndConditions} isPrivate />
      <Route path="/Home" exact strict component={Home} />
      {user.email === "akpoyibofortune@gmail.com" ||
      user.email === "livingyourdreamsrexers@gmail.com" ? (
        <Route path="/admin/files/all" exact strict component={Empty} />
      ) : (
        ""
      )}
      <Route component={NotFound} isPrivate />
    </Switch>
  ) : (
    <Switch>
      <Route path="/" key="/init2" exact strict component={Introduction} />
      <Route path="/Home" key="/Home1" exact strict component={Home} />
      <Route
        path="/user"
        key="/user2"
        exact
        strict
        component={RedirectToHome}
      />
      <Route
        path="/Chat"
        key="/Chat2"
        exact
        strict
        component={RedirectToHome}
      />
      <Route path="/About" exact strict component={About} isPrivate />
      <Route path="/TC" exact strict component={TermsAndConditions} isPrivate />
      <Route path="/Support" exact strict component={Support} isPrivate />

      <Route
        path="/_/auth/action"
        exact
        strict
        component={ResetPassword}
        isPrivate
      />

      {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
      <Route component={NotFound} />
    </Switch>
  );
};
export default Routes;
