import usePromise from "../Helpers/usePromise";
import { useEffect } from "react";
import { useUser } from "../Logic/Firebase/Auth";
const Api = (window.Tawk_API = window.Tawk_API || {});

let apiPromise = null;

export default function Tawk() {
  const loaded = usePromise(function () {
    if (apiPromise) return apiPromise;
    var s1 = document.createElement("script"),
      s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = "https://embed.tawk.to/5f9066b1f91e4b431ec66b34/default";
    s1.charset = "UTF-8";
    s1.setAttribute("crossorigin", "*");
    s0.parentNode.insertBefore(s1, s0);
    apiPromise = new Promise((r, j) => {
      Api.onLoad = function () {
        Api.hideWidget();
        r(true);
      };
    });
    return apiPromise;
  }, []);
  // const disable = useUser();
  useEffect(() => {
    if (loaded) {
      Api.showWidget();
      return function () {
        Api.hideWidget();
      };
    }
  }, [loaded]);
}
