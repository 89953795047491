import { Box, Typography, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "../../Components/SetupAos";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import useBreakpoint from "../../Helpers/useBreakpoint";
import DeployAnimation from "../../Images/lottie/deploy.json";
import DreamAnimation from "../../Images/lottie/dream.json";
import IdeaAnimation from "../../Images/lottie/idea.json";
import PaymentAnimation from "../../Images/lottie/payment.json";
import MaintenanceAnimation from "../../Images/lottie/maintenance.json";
import SeoAnimation from "../../Images/lottie/seo.json";
import TrafficAnimation from "../../Images/lottie/traffic.json";
import privacy from "../../Images/lottie/privacy.json";
import PushNotificationAnimation from "../../Images/lottie/PushNotificationAnimation.json";
const useStyles = makeStyles((theme) => {
  return {
    indented: {
      textIndent: theme.spacing(4),
    },
  };
});

const articles = [
  {
    header: "Bring your entrepreneurship idea to the spotlight",
    animation: IdeaAnimation,
    preview:
      "Take your business ideas one step further by showcasing it to over 4.5 billion users in the world. The internet makes the world a global village, and with the innovative solutions Rexdreams offers, you are one step away from gaining its full attention.",
  },
  {
    header: "Rexdreams turns dreams into software",
    animation: DreamAnimation,
    preview:
      "At Rexdreams, we understand how important it is to have a dream. That is why we take them very seriously. Regardless of whether you are building your personal website or the next Facebook, you can be assured that Rexdreams will do all it takes to turn that dream into a reality.",
  },
  {
    header:
      "With encrypted database by Rexdreams, absolute Privacy is in your hands only",
    animation: privacy,
    preview:
      "Do you know how the information your clients submit to your hosting platform is handled? How would you feel if you realized that another person has access to the data in your database? Do you know most developers only secure you from intruders only(hackers, malicious users e.t.c) but not from the hosting platform (Google, Microsoft, Amazon, or any other platforms) or even from the developers who created your apps or websites? When you create your apps or websites with Rexdreams, the hosting companies, our developers and the entire Rexdreams, don't know what is stored on your database, just YOU and those you allow access to. YOU are the king of your data, after all, your users trusted them to you alone and no one else.",
  },
  {
    header: "Search engine optimization (SEO)",
    animation: SeoAnimation,
    preview:
      "68% of online experiences begin with a search engine. And yet, less than 1% of Google searchers click on results from the second page. Consequently, Rexdreams puts in effort guided by years of experience into making sure that your product appear at the very top of the first page of online search results and remain there.",
  },
  {
    header: "Pay only when your traffic becomes huge",
    animation: TrafficAnimation,
    preview:
      "You shouldn't pay for hosting when you haven't started receiving large traffic, that's not fair. That is why Rexdreams' technology stack favors adaptive price models that can be maintained with very little or no cost. With such friendly and predictable pricing, our customers can make informed financial decisions and maximize revenue.",
  },
  {
    header: "Enjoy three month of free maintenance",
    animation: MaintenanceAnimation,
    preview:
      "Rexdreams maintain your apps and websites for three month totally free of any charges. Rexdreams will fix your bugs, make updates and follow your websites for optimal performance for good three months.",
  },
  {
    header: "Free Push Notification Service",
    animation: PushNotificationAnimation,
    preview:
      "You know how you visit one of those very popular brand websites, after leaving the website, they find a way to send you notification right from their website that isn't even open now to your phone or desktop, reminding you of either a new content, an item you placed in a cart and haven't yet purchased, latest news and all that stuff. What if we tell you those big brands pay heavily for those services every month🥵? With Rexdreams we do the hard work, we hard code it into your app, so you don't need to use extensions that will cost you money, waste your users data and even slow down your websites. We do these FREE OF ANY CHARGES for both your websites and Apps .",
  },
  {
    header: "Deploy with confidence",
    animation: DeployAnimation,
    preview:
      "Server failures, data breach, application crash, delayed response, software errors, et cetera; we know your nightmares. With Rexdreams, you can be rest assured you will not experience any of that. Every software and hardware component we employ is tested thoroughly for performance, security and accuracy before deployment to trusted servers. We do all the worrying so you never have to.",
  },
  {
    header: "Payment Integration",
    animation: PaymentAnimation,
    preview:
      "If you have ever tried to start an online business, you will realise that receiving revenue online can be a bit of a hassle for new users. For some regions, there are so many different payment platforms with their numerous pros and cons while for other regions, there is little or no support. Rexdreams handles all these issues for you while ensuring both you and your customers get the best payment experience.",
  },
];

const Article = ({ header, preview, animation, link, left }) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useBreakpoint();
  return (
    <Box
      m={2}
      mb={8}
      display="flex"
      flexDirection={left ? "row" : "row-reverse"}
      flexWrap="wrap"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        display="block"
        data-aos="fade"
        data-aos-duration={600}
        style={{
          maxWidth: "100%",
          width: "300px",
          minWidth: isMobile ? "100vw" : "360px",
          flexGrow: 1,
          margin: "0 50px",
        }}
      >
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: animation,
            rendererSettings: {
              preserveAspectRatio: "xMidYMidslice",
            },
          }}
        ></Lottie>
      </Box>
      <Box
        display="block"
        data-aos={left ? "fade-left" : "fade-right"}
        data-aos-duration={600}
        style={{
          width: "50%",
          minWidth: isMobile ? "70vw" : "40%",
          flexGrow: 1,
        }}
      >
        <Typography
          style={{
            marginBottom: theme.spacing(2) + "px",
            fontWeight: "bold",
          }}
          variant={isMobile ? "h4" : "h3"}
        >
          {header}
        </Typography>
        <Typography paragraph className={classes.indented}>
          {"    " + preview}
          {link ? <Link href={link}>...Read more</Link> : ""}
        </Typography>
      </Box>
    </Box>
  );
};

export default function ThirdPage({ className }) {
  return (
    <Box className={className} p={4}>
      {articles.map((e, i) => (
        <Article key={`${i}${i}`} {...e} left={i % 2 === 0} />
      ))}
    </Box>
  );
}
