import { makeStyles } from "@material-ui/core";
import { useState } from "react";
import { useUser } from "../../Logic/Firebase/Auth";
import { useListedJobs } from "../../Logic/Firebase/Jobs";
import App from "../App/App";
import CreateJob from "./CreateJob";
import Loading from "../../Components/Loading";
import UpdateProfile from "./UpdateProfile";
import { Redirect } from "react-router";
import JobList from "./JobList";
import { useSelector } from "react-redux";

const useStyles = makeStyles(function (theme) {
  return {
    main: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    modal: {
      maxWidth: "400px",
    },
  };
});

function Content() {
  const user = useUser();
  const classes = useStyles();
  const hasUnfinishedJob = useSelector((store) => store.jobData);
  const jobList = useListedJobs();
  let [collectInfo, setCollectInfo] = useState(),
    [createJob, setCreateJob] = useState();
  if (user === undefined || jobList === undefined)
    return <Loading className={classes.main} />;
  else if (user === null) return <Redirect to="/Home" />;
  else {
    if (!(user.displayName /* && user.phoneNumber*/)) {
      collectInfo = true;
    } else if (hasUnfinishedJob || jobList.size < 1) {
      createJob = true;
    }

    return (
      <div className={classes.main}>
        {createJob ? (
          <CreateJob
            onSubmit={() => {
              setCreateJob(false);
            }}
            user={user}
          />
        ) : (
          <JobList jobs={jobList} onCreateJob={() => setCreateJob(true)} />
        )}

        <UpdateProfile
          open={collectInfo}
          user={user}
          onClose={() => {
            setCollectInfo(false);
          }}
        />
      </div>
    );
  }
}

export default function User() {
  return (
    <App>
      <Content />
    </App>
  );
}
