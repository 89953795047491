import { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import {
  Button,
  ButtonBase,
  CircularProgress,
  makeStyles,
  Popover,
  Typography,
} from "@material-ui/core";

import SvgIcon from "@material-ui/core/SvgIcon";
import icon from "../../Images/logo.png";
import TextField from "@material-ui/core/TextField";
import useBreakpoint from "../../Helpers/useBreakpoint";
import {
  signUpEmail,
  logInEmail,
  useUser,
  resetPassword,
  logOut,
  signUpGoogle,
  serverError,
} from "../../Logic/Firebase/Auth";
import { useHistory } from "react-router";
import trackChange from "../../Helpers/trackChange";
import createErrorHandler from "../../Logic/Firebase/DefaultErrorHandler";
import Loading from "../Loading";
import Delay from "../../Components/Delay";
import { useRef } from "react";
import useDisableOnClick from "../../Helpers/useDisableOnClick";
import { delay } from "../../Helpers/delay";
import { ChevronLeft } from "@material-ui/icons";
const GoogleIcon = () => {
  return (
    <SvgIcon
      viewBox="0 0 24 24"
      width="24"
      height="24"
      style={{
        verticalAlign: "middle",
      }}
    >
      <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
        <path
          fill="#4285F4"
          d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"
        />
        <path
          fill="#34A853"
          d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"
        />
        <path
          fill="#FBBC05"
          d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"
        />
        <path
          fill="#EA4335"
          d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"
        />
      </g>
    </SvgIcon>
  );
};
const useStyles = makeStyles((theme) => ({
  iconCard: {
    borderRadius: "50%",
    overflow: "hidden",
    width: 75,
    height: 75,
    display: "inline-block",
    marginTop: 0,
    margin: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      flexAlign: "center",
    },
  },
  sectionEnd: {
    marginBottom: theme.spacing(2),
  },
  fullWidthInput: {
    width: "100%",
    // display: "block",
  },
  spacedBtn: {
    margin: theme.spacing(1, 2),
  },
  googleBtn: {
    display: "block",
    margin: theme.spacing(1, "auto", 2),
    padding: theme.spacing(1),
    minWidth: 0,
  },
  continueBtn: {
    margin: theme.spacing(2, "auto"),
    display: "block",
  },
  iconCardImg: {
    width: "100%",
    height: "100%",
  },
  textLink: {
    color: theme.palette.primary.main,
    display: "inline",
    font: "inherit",
  },
  modal: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(1, 4, 2),
    maxWidth: "80vw",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "45em",
    },
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
    },
  },
  formRow: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    "&>.MuiFormControl-root": {
      flexGrow: 1,
    },
  },
}));

function IconCard({ imageSrc = icon }) {
  const classes = useStyles();
  return (
    <div className={classes.iconCard}>
      <img
        alt="company icon"
        src={imageSrc}
        className={classes.iconCardImg}
      ></img>
    </div>
  );
}

function SignupCard({ anchorEl, id, onClose }) {
  const isMenuOpen = Boolean(anchorEl);
  const isSmallScreen = useBreakpoint();
  const [resettingPassword, setResetPassword] = useState();
  const user = useUser();
  const [signingIn, setSigningIn] = useState();

  const position = isSmallScreen
    ? { vertical: "center", horizontal: "center" }
    : { vertical: "top", horizontal: "right" };
  return (
    <Popover
      anchorEl={isSmallScreen || anchorEl === true ? null : anchorEl}
      anchorOrigin={position}
      id={id}
      keepMounted
      transformOrigin={position}
      open={isMenuOpen}
      onClose={onClose}
    >
      {user ? (
        <SignedIn />
      ) : resettingPassword ? (
        <ResetPassword setResetPassword={setResetPassword} />
      ) : (
        <SignupForm
          signingIn={signingIn}
          setSigningIn={setSigningIn}
          setResetPassword={setResetPassword}
        />
      )}
    </Popover>
  );
}
function SignedIn() {
  
  const classes = useStyles();
  const user = useUser();
  const history = useHistory();
  const proceed = () => {
    history.push("/user");
  };
  const [errorText, setErrorText] = useState("");
  const handleError = createErrorHandler(setErrorText);
  const [loading, setLoading] = useState(false);

  const signOut = () => {
    logOut()
      .then(() => {
        history.push("/Home");
      })
      .catch(handleError);
  };
  return (
    <div className={classes.modal}>
      <IconCard />
      <Typography className={classes.sectionEnd} variant="h5">
        &#8288;Let's get started&#8288;
      </Typography>
      <Typography color="error">{errorText}</Typography>
      <Typography className={classes.sectionEnd}>
        {`Currently signed in as ${user.displayName || user.email}`}
      </Typography>
      <div className={classes.formRow}>
        <Button
          className={classes.spacedBtn}
          variant="contained"
          color="primary"
          disabled={false /*//TODO*/}
          onClick={proceed}
          style={{opacity:loading?0:1}}
        >
          Continue
        </Button>
        <Button
          className={classes.spacedBtn}
          onClick={signOut}
          variant="outlined"
          color="secondary"
        >
          Sign out
        </Button>
      </div>
    </div>
  );
}
function ResetPassword({ setResetPassword }) {
  const classes = useStyles();
  const [email, setEmail] = useState();
  const [errorText, setErrorText] = useState();
  const handleError = createErrorHandler(setErrorText);
  const [emailSent, setEmailSent] = useState();
  const [pending, proceed] = useDisableOnClick(function () {
    if (email) {
      return resetPassword(email)
        .then(function () {
          setEmailSent(true);
          return delay(30000);
        })
        .catch(handleError);
    } else {
      setErrorText("Please enter email address");
      return Promise.resolve(false);
    }
  }, true);
  return (
    <div className={classes.modal}>
      <IconCard />
      <Typography className={classes.sectionEnd} variant="h5">
        &#8288;Account Recovery&#8288;
      </Typography>
      <Typography color="error">{errorText}</Typography>
      {emailSent ? (
        <>
          <Typography>
            An email has been sent to {email} to help you reset your password.
          </Typography>
          {pending ? undefined : (
            <Typography>
              Didn't receive the email,{" "}
              <ButtonBase className={classes.textLink} onClick={proceed}>
                Resend
              </ButtonBase>
            </Typography>
          )}
        </>
      ) : (
        <>
          <Typography>
            Please enter the email address used to sign up.
          </Typography>

          <form noValidate onSubmit={proceed}>
            <div className={classes.formRow}>
              <TextField
                onChange={trackChange(setEmail)}
                label="Email"
                type="email"
                fullWidth
                name="email"
                variant="outlined"
                className={classes.fullWidthInput}
              />
            </div>
            <Button
              className={classes.continueBtn}
              variant="contained"
              color="primary"
              onClick={proceed}
            >
              Continue
            </Button>
          </form>
        </>
      )}
      <Button
        variant="text"
        color="secondary"
        onClick={() => {
          setResetPassword(false);
        }}
      >
        <ChevronLeft />
        Return to Login
      </Button>
    </div>
  );
}
function SignupForm({ setResetPassword, signingIn, setSigningIn }) {
  const classes = useStyles();
  const isSmallScreen = useBreakpoint();
  const [errorText, setErrorText] = useState("");
  const [email, setEmail] = useState();
  const history = useHistory();
  const [password, setPassword] = useState();
  const [password2, setPassword2] = useState();
  const [error] = useState(serverError[0]);
  const [loading, setLoading] = useState(false);
  const toggleSigningIn = () => {
    setSigningIn(!signingIn);
  };
  useEffect(() => {
    setErrorText("");
  }, [signingIn]);

  useEffect(() => {
    error !== undefined && setErrorText(error);
  }, [error]);

  const handleError = createErrorHandler(setErrorText);
  const enableResetPassword = () => {
    setResetPassword(true);
  };

  const ProceedGoogle = () => {
    setLoading(true);
    signUpGoogle({ useRedirect: isSmallScreen })
      .finally(function () {
        setLoading(false);
        history.push("/user");
      })
      .catch(handleError);
  };
  const proceed = () => {
    if (email && password && (signingIn || password2)) {
      setErrorText("");

      if (!signingIn && password !== password2) {
        return setErrorText("Passwords do not match");
      } else {
        setErrorText(null);
        setLoading(true);
        (signingIn ? logInEmail : signUpEmail)({ email, password })
          .then(function () {
            if(signingIn){history.push("/user")};
          })
          
          .catch((e)=>{setLoading(false);handleError(e)});
      }
    } else {
      setLoading(false);
      setErrorText("All fields must be filled");
    }
  };

  return (
    <div className={classes.modal}>
      <IconCard />
      <Typography className={classes.sectionEnd} variant="h5">
        &#8288;Let's get started&#8288;
      </Typography>
      <Typography color="error">{errorText}</Typography>
      <form noValidate onSubmit={proceed}>
        <div className={classes.formRow}>
          <TextField
            onChange={trackChange(setEmail)}
            label="Email"
            type="email"
            name="email"
            variant="outlined"
            className={classes.fullWidthInput}
          />
          <TextField
            onChange={trackChange(setPassword)}
            label="Password"
            type="password"
            name={signingIn ? "password" : "newpassword"}
            variant="outlined"
          />
          {signingIn ? undefined : (
            <TextField
              onChange={trackChange(setPassword2)}
              label="Confirm Password"
              type="password"
              name="confirmpassword"
              variant="outlined"
            />
          )}
        </div>
        <Button
          className={classes.continueBtn}
          variant="contained"
          color="primary"
          onClick={proceed}
        >
          Continue
        </Button>
        <Typography align="center">
          {signingIn ? "or log in with" : "or sign up with"}
        </Typography>
        {loading ? (
          <Loading />
        ) : (
          <Button
            className={classes.googleBtn}
            variant="contained"
            color={loading ? "transparent" : "default"}
            onClick={ProceedGoogle}
          >
            <GoogleIcon />
          </Button>
        )}
      </form>

      <Typography>
        {signingIn ? "Don't have an account? " : "Already have an account? "}
        <ButtonBase className={classes.textLink} onClick={toggleSigningIn}>
          {signingIn ? "Create an account" : "Log in"}
        </ButtonBase>
      </Typography>
      {signingIn ? (
        <Typography>
          {"Forgot Password? "}
          <ButtonBase
            className={classes.textLink}
            onClick={enableResetPassword}
          >
            Reset password
          </ButtonBase>
        </Typography>
      ) : undefined}
    </div>
  );
}

export default SignupCard;
