import {
  PhoneAuthProvider,
  RecaptchaVerifier,
  updatePhoneNumber,
  updateProfile,
} from "@firebase/auth";
import {
  Button,
  makeStyles,
  Modal,
  Popover,
  TextField,
  Typography,
} from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import { auth } from "../../Logic/Firebase/Init";
import trackChange from "../../Helpers/trackChange";
import AccountProfile from "../../Components/AccountProfile";
import { uploadFileAndGetUrl } from "../../Logic/Firebase/ActionCreators";

const useStyles = makeStyles(function (theme) {
  return {
    sectionEnd: {
      marginBottom: theme.spacing(2),
    },
    continueBtn: {
      margin: theme.spacing(2, "auto"),
      display: "block",
    },
    modalRoot: {
      padding: theme.spacing(2),
    },
    textField: {
      margin: theme.spacing(1),
      width: "300px",
    },
    fileInput: {
      position: "absolute",
      opacity: 0,
      maxWidth: "5px",
      maxHeight: "5px",
    },
  };
});
//TODO handle errors
const UpdateProfile = function ({ user, open, onClose }) {
  const classes = useStyles();
  const [errorText, setErrorText] = useState();
  const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber);
  const [displayName, setDisplayName] = useState(user.displayName);
  const [photoURL, setPhotoURL] = useState(null);
  const [verificationCode, setVerificationCode] = useState();
  const [stage, setStage] = useState(1);
  const verificationId = useRef();
  const applicationVerifier = useRef();
  const proceed = async function (ev) {
    ev.preventDefault();
    if (photoURL === null) {
      setErrorText("You must upload a profile picture");
    } else if (displayName !== user.displayName) {
      let timeCreated = new Date().getTime();
      const id = "IMG-" + timeCreated + "-" + user.uid;
      let imageUrl = await uploadFileAndGetUrl(photoURL, id);
      await updateProfile(user, { displayName, photoURL: imageUrl });
    }
    // if (false && phoneNumber !== user.phoneNumber) {
    //   const provider = new PhoneAuthProvider(auth);
    //   verificationId.current = await provider.verifyPhoneNumber(
    //     phoneNumber,
    //     applicationVerifier.current
    //   );
    //   setStage(2);
    // } else {
    onClose();
    // }
  };
  const ref = useRef();
  useEffect(function () {
    if (ref.current) {
      applicationVerifier.current = new RecaptchaVerifier(
        ref.current,
        undefined,
        auth
      );
    }
  }, []);
  const verify = async function (ev) {
    ev.preventDefault();
    await updatePhoneNumber(
      user,
      PhoneAuthProvider.credential(verificationId.current, verificationCode)
    );
    onClose();
  };
  const centered = { horizontal: "center", vertical: "top" };
  const previewAndSendImage = function (ev) {
    const files = ev.target.files;
    if (files.length === 0) {
      return;
    }
    const image = files[0];
    switch (image.type) {
      case "image/apng":
      case "image/bmp":
      case "image/gif":
      case "image/jpeg":
      case "image/pjpeg":
      case "image/png":
      case "image/svg+xml":
      case "image/tiff":
      case "image/webp":
      case "image/x-icon":
        //allow
        break;
      default:
        return;
    }
    setPhotoURL(image);
  };
  return (
    <Popover open={!!open} transformOrigin={centered} anchorOrigin={centered}>
      <div className={classes.modalRoot}>
        <Typography className={classes.sectionEnd} variant="h5">
          &#8288;
          {stage === 1
            ? "Help us to know you better"
            : "Please enter the verification code sent to your phone number"}
          &#8288;
        </Typography>
        <Typography color="error">{errorText}</Typography>

        <form onSubmit={stage === 1 ? proceed : verify}>
          <TextField
            onChange={trackChange(
              stage === 1 ? setDisplayName : setVerificationCode
            )}
            label="Name / Company Name"
            type="text"
            name="name"
            variant="outlined"
            className={classes.textField}
          />
          <span style={{ position: "relative", marginRight: "50px" }}>
            <input
              type="file"
              className={classes.fileInput}
              accept="image/*"
              onChange={(e) => previewAndSendImage(e)}
              id="Chat-pickImage"
            />
            <label htmlFor="Chat-pickImage">
              {photoURL ? (
                <b style={{ color: "green" }}>
                  Photo Saved Successfully. Refresh page to change
                </b>
              ) : (
                <>
                  <AccountProfile />
                  {""}
                  <p>Upload a profile picture</p>
                </>
              )}
            </label>
          </span>

          {/* {stage === 1 ? (
            <TextField
              onChange={trackChange(setPhoneNumber)}
              label="Phone Number"
              type="tel"
              name="tel"
              variant="outlined"
              className={classes.textField}
            />
          ) : undefined} */}
          {stage === 1 ? <div ref={ref}></div> : undefined}
          <Button
            className={classes.continueBtn}
            variant="contained"
            color="primary"
            type="submit"
          >
            Continue
          </Button>
        </form>
      </div>
    </Popover>
  );
};
export default UpdateProfile;
