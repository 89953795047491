import { Button, Popover, useTheme } from "@material-ui/core";

const Modal = ({ handleClose, show, children }) => {
  const theme = useTheme();
  return (
    <Popover
      open={show}
      style={{ padding: theme.spacing(2) }}
      transformOrigin={{ vertical: "center", horizontal: "center" }}
      anchorOrigin={{ vertical: "center", horizontal: "center" }}
      onClose={handleClose}
    >
      <section className="modal-main">
        {children}
        <Button
          variant="outlined"
          color="secondary"
          style={{ margin: theme.spacing(2) }}
          onClick={handleClose}
        >
          Close
        </Button>
      </section>
    </Popover>
  );
};

export default Modal;
