export function errorCodeToString(code) {
  switch (code) {
    case "already-exists":
      return "Document already exists";
    case "network-request-failed":
      return "Unable to connect";
    case "email-already-in-use":
      return "This email is already in use";
    case "invalid-action-code":
      return "Reset link is now invalid";
    case "user-not-found":
      return "Account does not exist";
    case "invalid-argument":
      return "Invalid details";
    case "wrong-password":
      return "Password is not correct";
    default:
      return "Unknown error";
  }
}
export default function createErrorHandler(setError) {
  /**
   * @param {import("@firebase/app").FirebaseError}
   */
  return function (err) {
    console.log({ err });
    setError(errorCodeToString(String(err.code).replace("auth/", "")));
  };
}
