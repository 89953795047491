import React from "react";
import LoadingScreen from "react-loading-screen";
export default function OurLoadingScreen() {
  return (
    //...
    <LoadingScreen
      loading={true}
      bgColor="#000000"
      spinnerColor="gold"
      textColor="gold"
      // logoSrc={logo}
      text="You think it, we create it"
    />
  );
}
