import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import { useState } from "react";
import {
  STATUS_AWAITING_CONFIRMATION,
  STATUS_SUBMITTED,
  STATUS_AWAITING_PAYMENT,
  STATUS_CLOSED,
  STATUS_FINISHED,
  STATUS_IN_PROGRESS,
  STATUS_NEEDS_CLARIFICATION,
  STATUS_CANCELLED,
  STATUS_UNDER_REVIEW,
} from "../../Logic/Firebase/JobStatus";
import useBreakpoint from "../../Helpers/useBreakpoint";
import TimeAgo from "../../Components/SetupTimeAgo";
import { blue, green, yellow, grey, red } from "@material-ui/core/colors";
import { Add, ChevronRight } from "@material-ui/icons";
import { useHistory } from "react-router";
import Modal from "../Modal";

const useStyles = makeStyles((theme) => ({
  dot: {
    borderRadius: "0.375em",
    height: "0.75em",
    width: "0.75em",
    display: "inline-block",
  },
}));
function getStatusColor(status) {
  switch (status) {
    case STATUS_SUBMITTED:
      return blue[500];
    case STATUS_CLOSED:
    case STATUS_FINISHED:
      return grey[500];
    case STATUS_AWAITING_PAYMENT:
    case STATUS_AWAITING_CONFIRMATION:
    case STATUS_NEEDS_CLARIFICATION:
    case STATUS_UNDER_REVIEW:
      return yellow[500];
    case STATUS_IN_PROGRESS:
      return green[500];
    case STATUS_CANCELLED:
      return red[500];
    default:
      return grey[500];
  }
}
function printTime(timeStamp) {
  if (!timeStamp) {
    return "Pending";
  }
  return TimeAgo.format(new Date(timeStamp));
}

/**
 *
 * @param {Object} props
 * @param {Map<String,Object>} props.jobs
 * @param {function} props.onCreateJob
 * @returns
 */
const JobDetails = ({
  description,
  title,
  dateDue,
  dateCreated,
  status,
  log,
  paid,
  amount,
  expand = false,
}) => {
  const classes = useStyles();

  return (
    <>
      <CardHeader title={title} />
      <Box p={2} paddingTop={0}>
        {expand
          ? description.map(({ question, answer }) => (
              <Typography paragraph>
                {`Question: ${question}`}
                <br /> {`Answer: ${answer || "No answer."}`}
              </Typography>
            ))
          : undefined}
        <Typography>Date created: {printTime(dateCreated)}</Typography>
        <Typography>Date due: {printTime(dateDue)}</Typography>
        <Typography>
          <span
            className={classes.dot}
            style={{
              backgroundColor: getStatusColor(status),
            }}
          ></span>{" "}
          {status}
        </Typography>
        <Typography>Cost: {amount ? "NGN" + amount : "Pending"}</Typography>
        {amount && !paid ? (
          <Button variant="contained" color="primary">
            <Add />
            Proceed to pay
          </Button>
        ) : undefined}
      </Box>
    </>
  );
};
const JobModal = (props) => {
  const [showJobs, setShowJobs] = useState(false);
  return (
    <Grid item xs={12} sm={6} lg={4} xl={3}>
      <ButtonBase style={{ width: "100%" }} onClick={() => setShowJobs(true)}>
        <Card style={{ width: "100%", textAlign: "left" }}>
          <JobDetails {...props} expand={false} />
        </Card>
      </ButtonBase>
      <Modal show={showJobs} handleClose={() => setShowJobs(false)}>
        <JobDetails {...props} expand={true} />
      </Modal>
    </Grid>
  );
};

export default function JobList({ onCreateJob, jobs }) {
  const history = useHistory();
  const gotoMessages = function () {
    history.push("/Chat");
  };
  const theme = useTheme();
  const isMobile = useBreakpoint();

  return (
    <div style={{ margin: theme.spacing(1, 2, 2) }}>
      <div
        style={{
          display: "flex",
          marginBottom: theme.spacing(2),
          justifyContent: isMobile ? "center" : "space-between",
        }}
      >
        <Button onClick={onCreateJob} variant="contained" color="primary">
          <Add />
          Create New Project
        </Button>
        <Button onClick={gotoMessages} variant="text" color="primary">
          View Messages
          <ChevronRight />
        </Button>
      </div>
      <Grid container spacing={2}>
        {Array.from(jobs)
          .sort(
            ([, { body: a }], [, { body: b }]) => b.dateCreated - a.dateCreated
          )
          .map(([key, job]) => (
            <JobModal {...job.body} />
          ))}
      </Grid>
    </div>
  );
}
