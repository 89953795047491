const initialState = {};
export const JOB_CHANGED = "JOB_CHANGED";
export const SAVE_JOB_DATA = "SAVE_JOB_DATA";
export const CLEAR_JOB_DATA = "CLEAR_JOB_DATA";
const AllReducers = (state = initialState, action) => {
  switch (action.type) {
    case JOB_CHANGED:
      return {
        ...state,
        jobCounter: (state.jobCounter || 0) + 1,
      };
    case SAVE_JOB_DATA:
      return {
        ...state,
        jobData: action.payload,
      };
    case CLEAR_JOB_DATA:
      return {
        ...state,
        jobData: null,
      };

    default:
      return state;
  }
};
export default AllReducers;
