import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDoVFWV91IWVhHGVMElcNfkXWp0ScKtc2s",
  authDomain: "rexdreams-97859.firebaseapp.com",
  projectId: "rexdreams-97859",
  storageBucket: "rexdreams-97859.appspot.com",
  messagingSenderId: "707026801376",
  appId: "1:707026801376:web:db289a68b81b0f91e5b685",
  measurementId: "G-D2N1RMR52R",
};
initializeApp(firebaseConfig);

export const auth = getAuth();
export const storage = getStorage();
export const firestore = getFirestore();
// if (process.env.NODE_ENV !== "production") {
//   connectAuthEmulator(auth, "http://localhost:9099");
//   connectFirestoreEmulator(firestore, "localhost", 8080);
//   connectStorageEmulator(firestore, "localhost", 9199);
// }
