import React, { Fragment, useMemo, useRef } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { CssBaseline, Paper, ThemeProvider } from "@material-ui/core";
import { TextInput } from "./TextInput.js";
import { MessageLeft, MessageRight } from "./Message";
import useBreakpoint from "../../Helpers/useBreakpoint";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import {
  getReceiverName,
  useReceivedMessages,
  useSentMessages,
} from "../../Logic/Firebase/Messages.js";
import { useUser } from "../../Logic/Firebase/Auth.js";
import Loading from "../../Components/Loading.js";
import themeDark from "../../Components/DarkTheme.js";
import { Provider } from "react-redux";
import store from "../../Logic/Redux/Store.js";
import "./Message.scss";
import useAnimatedScroll from "../../Helpers/animatedScroll";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: "80vw",
      maxWidth: "720px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      position: "relative",
      height: "100%",
      maxHeight: "100%",
      [theme.breakpoints.down("xs")]: {
        flexGrow: 1,
        width: "100%",
        height: 0,
      },
    },
    container: {
      width: "100vw",
      height: "100vh",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        alignItems: "start",
        flexDirection: "column",
      },
    },
  })
);

export default function App() {
  return (
    <Fragment>
      <ThemeProvider theme={Object.assign({}, themeDark)}>
        <CssBaseline />
        <Provider store={store}>
          <Chat />
        </Provider>
      </ThemeProvider>
    </Fragment>
  );
}
const Pill = function ({ day }) {
  return (
    <div style={{ textAlign: "center" }}>
      <Paper
        elevation={1}
        style={{
          display: "inline-block",
          fontSize: "90%",
          borderRadius: "5px",
          padding: "4px 8px",
          margin: "32px auto 16px",
          background: "#f0f0f0",
          fontWeight: "lighter",
          color: "#777777",
        }}
      >
        {day}
      </Paper>
    </div>
  );
};
function Chat() {
  const classes = useStyles();
  const history = useHistory();
  const uid = useUser()?.uid;
  const sentMessages = useSentMessages();
  const receivedMessages = useReceivedMessages();
  const receiverDisplayName = getReceiverName();
  const animateScrolling = useAnimatedScroll();
  const container = useRef();
  const scrollToBottom = () => {
    if (container.current) {
      animateScrolling({
        element: container.current,
        targetScroll:
          container.current.scrollHeight - container.current.clientHeight,
      });
    }
  };
  const messages = useMemo(
    () =>
      sentMessages && receivedMessages
        ? Array.from(sentMessages)
            .concat(Array.from(receivedMessages))
            .sort(([, a], [, b]) => a.body.timeCreated - b.body.timeCreated)
        : "Chats between Rexdreams and You will show here",
    [sentMessages, receivedMessages]
  );
  let lastDay = 0;
  let lastData = null;
  const now = new Date();
  const midnight =
    now.getTime() -
    (now.getSeconds() + (now.getMinutes() + now.getHours() * 60) * 60) * 1000;
  const lastMidnight = midnight - 24 * 60 * 60 * 1000;
  const printDay = (time) =>
    time > midnight
      ? "Today"
      : time > lastMidnight
      ? "Yesterday"
      : new Date(time).toDateString();
  return (
    <div className={classes.container}>
      {/* <Button
        color="default"
        onClick={() => {
          history.push("/");
        }}
      >
        <ChevronLeft color="secondary" fontSize="large" />
      </Button> */}

      <Paper className={classes.paper} elevation={2}>
        <div className="Chat-root">
          <div ref={container} className="Chat-container">
            {messages ? (
              typeof messages === "string" || messages.length === 0 ? (
                <MessageLeft
                  displayname={"Rexdreams"}
                  timestamp={
                    new Date().getHours() +
                    ":" +
                    new Date().getMinutes() +
                    ":" +
                    new Date().getSeconds()
                  }
                  message={
                    <>
                      Chats between Rexdreams and You will show here. <br />
                      Click <b>Type Message</b> to begin a conversation with us
                    </>
                  }
                />
              ) : (
                messages.map(([key, data], i) => {
                  const { receiverId, senderId, status, body: message } = data;
                  const day = printDay(message.timeCreated);
                  const timestamp = new Date(
                    message.timeCreated
                  ).toLocaleTimeString();
                  const isFollow =
                    lastData &&
                    lastData.senderId === senderId &&
                    message.timeCreated - lastData.body.timeCreated < 30000;
                  const bubble =
                    receiverId === uid ? (
                      <MessageLeft
                        key={key}
                        isFollow={isFollow}
                        message={message.content}
                        imageUrl={message.imageUrl}
                        displayname={receiverDisplayName}
                        timestamp={timestamp}
                      />
                    ) : (
                      <MessageRight
                        key={key}
                        isFollow={isFollow}
                        imageUrl={message.imageUrl}
                        message={message.content}
                        timestamp={timestamp}
                      />
                    );
                  lastData = data;
                  if (day === lastDay) return bubble;
                  lastDay = day;
                  // if (day === "Today" && i === 0) return bubble;
                  return (
                    <Fragment key={day + key}>
                      <Pill day={day} />
                      {bubble}
                    </Fragment>
                  );
                })
              )
            ) : (
              <Loading />
            )}
          </div>
        </div>
        <TextInput onSend={scrollToBottom} />
      </Paper>
    </div>
  );
}
