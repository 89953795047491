import { Collapse, Typography } from "@material-ui/core";
import useBreakpoint from "../../Helpers/useBreakpoint";
import {
  ChevronRight,
  ExpandMore as ExpandMoreIcon,
  Instagram,
  LinkedIn,
  WhatsApp,
  Mail,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import { useState } from "react";
const useStyles = makeStyles(function (theme) {
  return {
    footerContainer: {
      width: "auto",
      display: "flex",
      justifyContent: "space-around",
      padding: theme.spacing(2, 2, 8),
      flexWrap: "wrap",
      [theme.breakpoints.down("sm")]: {
        "&>.MuiTypography-root": {
          width: "100%",
        },
      },
      [theme.breakpoints.down("xs")]: {
        display: "block",
      },
    },
    footerHeader: {
      minWidth: "32px",
    },
    footerItem: {
      listStyle: "none",
      padding: "8px 0",
      "& a": {
        color: "inherit",
        textDecoration: "none",
      },
    },
    footerColumn: {
      flexGrow: 1,
      padding: theme.spacing(0, 4),
    },
  };
});
const routes = [
  {
    title: "Company",
    links: [
      {
        title: "About Us",
        href: "/About",
      },
      {
        title: "Jobs",
        href: { pathname: "https://linkedin.com/in/rex-dreams-859090224" },
      },
    ],
  },
  {
    title: "Support",
    links: [
      {
        title: "Social",
      },
      {
        title: "Privacy and terms",
        href: "/TC",
      },
      {
        title: "Sitemap",
        href: "/",
      },
    ],
  },
];
const CollapsibleList = ({
  data: { title: header, links: children },
  open = false,
  onSelect,
  showIcons,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.footerColumn}>
      <Typography
        variant="h6"
        onClick={onSelect}
        className={classes.footerHeader}
      >
        {header}
        {showIcons ? (
          <div style={{ float: "right" }}>
            <span
              style={{
                display: "inline-block",
                verticalAlign: "middle",
                height: "24px",
              }}
            >
              {open ? <ExpandMoreIcon /> : <ChevronRight />}
            </span>
          </div>
        ) : (
          ""
        )}
      </Typography>
      <Collapse in={open}>
        <ul style={{ padding: 0, margin: 0 }}>
          {children.map(
            (e) =>
              e.title === "Social" && (
                <>
                  <a
                    href="https://www.instagram.com/livingyourdreamsrexers"
                    target="_self"
                  >
                    <Instagram />
                  </a>{" "}
                  <a
                    href="https://linkedin.com/in/rex-dreams-859090224"
                    target="_self"
                  >
                    <LinkedIn />
                  </a>{" "}
                  <a href="https://wa.me/message/O6KICPH652QDP1" target="_self">
                    <WhatsApp />
                  </a>{" "}
                  <a href="mailto:liveyourdreams@rexdreams.com">
                    <Mail />
                  </a>
                </>
              )
          )}
          {children.map((e) => (
            <li key={e.title} className={classes.footerItem}>
              <Link
                to={e.href}
                target={e.title === "Jobs" ? "_blank" : "_self"}
              >
                <Typography>{e.title === "Social" ? "" : e.title}</Typography>
              </Link>
            </li>
          ))}
        </ul>
      </Collapse>
    </div>
  );
};

export default function Footer() {
  const isReallyBigScreen = useBreakpoint("lg");
  const classes = useStyles();
  const isMobile = useBreakpoint();
  const [selected, setSelected] = useState(-1);
  const handleSelect = function (key) {
    return function () {
      if (selected === key) setSelected(-1);
      else setSelected(key);
    };
  };
  return (
    <div className={classes.footerContainer}>
      {isReallyBigScreen ? (
        <Typography className="App-brandname" variant="h3">
          Rexdreams
        </Typography>
      ) : (
        ""
      )}
      {routes.map((data, i) => (
        <CollapsibleList
          key={`${i}`}
          showIcons={isMobile}
          open={isMobile ? selected === i : true}
          onSelect={isMobile ? handleSelect(i) : undefined}
          data={data}
        />
      ))}
    </div>
  );
}
