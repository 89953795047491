import { Box, makeStyles, Typography, useTheme } from "@material-ui/core";
import ReactPlayer from "react-player";
import shortExplanation from "../../Images/us/shortExplanation.mp4";
import thumbnail from "../../Images/us/thumbnail.jpeg";

const useStyles = makeStyles((theme) => {
  return {
    grid: {
      display: "flex",
      margin: theme.spacing(4),
      justifyContent: "center",
      [theme.breakpoints.down("xs")]: {
        alignItems: "center",
        width: "100%",
      },
    },
  };
});
const ReviewCard = function () {
  return (
    <ReactPlayer
      url={shortExplanation}
      controls={true}
      light={thumbnail}
      pip={true}
      playing={true}
    />
  );
};
export default function ShortExplanation() {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <Box p={4} bgcolor={theme.palette.background.contrast}>
      <Typography variant="h5">Quick Explanation on Why Us?</Typography>
      <div className={classes.grid}>
        <ReviewCard />
      </div>
    </Box>
  );
}
