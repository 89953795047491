import logo from "../Images/logo.png";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const Introduction = (props) => {
  setTimeout(() => {
    props.history.push("/Home");
  }, 13000);
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <header className="App-header">
        <div>
          <img
            src={logo}
            alt="logo"
            width="150"
            height="150"
            style={{ borderRadius: "50px" }}
            className="rounded Introduction-logo mb-5"
          />
          <div
            className="Introduction-name Introduction-Rex"
            style={{ color: "gold" }}
          >
            REXDREAMS
          </div>
          <div className="Introduction-Dreams" style={{ color: "gold" }}>
            You think it, We create it
          </div>
        </div>
      </header>
    </div>
  );
};
export default Introduction;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100vw",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);
