import React from "react";
import Lottie from "react-lottie";
import pageNotFound from "../Images/lottie/pageNotFound";
export default function Loading() {
  return (
    //...
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: pageNotFound,
        rendererSettings: {
          preserveAspectRatio: "xMidYMidslice",
        },
      }}
    />
  );
}
