import { doc, onSnapshot } from "@firebase/firestore";
import { createContext, useContext, useEffect } from "react";
import useCancellableState from "../../Helpers/cancellable";
import { useUser } from "./Auth";
import { firestore } from "./Init";
import {decryptData, encryptData} from '../Encrypt';
const USERS_COLLECTION = "users";

export function useUserData() {
  return useContext(UserDataContext);
}
const UserDataContext = createContext(null);
export function UserDataProvider({ children }) {
  const [data, setData] = useCancellableState();
  const user = useUser();
  useEffect(
    function () {
      if (!user) return;
      return onSnapshot(
        doc(firestore, USERS_COLLECTION, user.uid),
        function (doc) {
          setData(decryptData(doc.data()));
        }
      );
    },
    [user, setData]
  );
  return (
    <UserDataContext.Provider value={data}>{children}</UserDataContext.Provider>
  );
}

export async function cacheUserData(data) {
  return decryptData({
    secureData: window.localStorage.setItem(
      "cached-user-data",
      encryptData(data).secureData
    ),
  });
}
export async function clearCachedUserData(data) {
  return decryptData({
    secureData: window.localStorage.removeItem("cached-user-data"),
  });
}
export function getCachedUserData(data) {
  return decryptData({
    secureData: window.localStorage.getItem("cached-user-data"),
  });
}
