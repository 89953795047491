import App from "./App/App";
import { useLocation, useHistory } from "react-router-dom";
import { finishPasswordReset } from "../Logic/Firebase/Auth";
import { useEffect, useMemo, useState } from "react";
import { confirmPasswordReset, updatePassword } from "@firebase/auth";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useBreakpoint from "../Helpers/useBreakpoint";
import createErrorHandler from "../Logic/Firebase/DefaultErrorHandler";
import { Button, TextField, Typography } from "@material-ui/core";
import trackChange from "../Helpers/trackChange";
const useStyles = makeStyles((theme) => ({
  iconCard: {
    borderRadius: "50%",
    overflow: "hidden",
    width: 75,
    height: 75,
    display: "inline-block",
    marginTop: 0,
    margin: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      flexAlign: "center",
    },
  },
  sectionEnd: {
    marginBottom: theme.spacing(2),
  },
  fullWidthInput: {
    width: "100%",
    // display: "block",
  },
  spacedBtn: {
    margin: theme.spacing(1, 2),
  },
  googleBtn: {
    display: "block",
    margin: theme.spacing(1, "auto", 2),
    padding: theme.spacing(1),
    minWidth: 0,
  },
  continueBtn: {
    margin: theme.spacing(2, "auto"),
    display: "block",
  },
  iconCardImg: {
    width: "100%",
    height: "100%",
  },
  textLink: {
    color: theme.palette.primary.main,
    display: "inline",
    font: "inherit",
  },
  modal: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(1, 4, 2),
    maxWidth: "80vw",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "45em",
    },
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
    },
  },
  formRow: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    "&>.MuiFormControl-root": {
      flexGrow: 1,
    },
  },
}));

function ResetPasswordContent() {
  const location = useLocation();
  const [defaultEmail, setDefaultEmail] = useState(() => {
    return window.localStorage.userSigninEmail;
  });
  const [loading, setLoading] = useState(!!defaultEmail);
  const classes = useStyles();
  const history = useHistory();
  const isSmallScreen = useBreakpoint();
  const [errorText, setErrorText] = useState("");
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [password2, setPassword2] = useState();
  // const [error] = useState(serverError[0]);

  const handleError = createErrorHandler(setErrorText);

  const proceed = () => {
    if (email && password && password2) {
      setErrorText("");

      if (password !== password2) {
        return setErrorText("Passwords do not match");
      } else {
        setErrorText(null);
        const params = location.search
          ? new URLSearchParams(location.search)
          : undefined;

        const oobCode = params?.get("oobCode");
        const continueUrl = new URL(params?.get("continueUrl"));
        finishPasswordReset(oobCode, password).then(() => {
          history.push(continueUrl.pathname);
        }, handleError);
      }
    } else {
      setErrorText("All fields must be filled");
    }
  };

  return (
    <div className={classes.modal}>
      <Typography className={classes.sectionEnd} variant="h5">
        &#8288;Set new password&#8288;
      </Typography>
      <Typography color="error">{errorText}</Typography>
      <form noValidate onSubmit={proceed}>
        <div className={classes.formRow}>
          <TextField
            onChange={trackChange(setEmail)}
            label="Email"
            type="email"
            name="email"
            variant="outlined"
            defaultValue={defaultEmail}
            className={classes.fullWidthInput}
          />
          <TextField
            onChange={trackChange(setPassword)}
            label="Password"
            type="password"
            name="newpassword"
            variant="outlined"
          />
          <TextField
            onChange={trackChange(setPassword2)}
            label="Confirm Password"
            type="password"
            name="confirmpassword"
            variant="outlined"
          />
        </div>
        <Button
          className={classes.continueBtn}
          variant="contained"
          color="primary"
          onClick={proceed}
        >
          Reset Password
        </Button>
      </form>
    </div>
  );
}
export default function ResetPassword() {
  return (
    <App>
      <ResetPasswordContent />
    </App>
  );
}
