import { Box, Popover, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { submitJob } from "../../Logic/Firebase/Jobs";
import { useState, useRef, useEffect } from "react";
import { auth } from "../../Logic/Firebase/Init";
import { SERVICE_ID, TEMPLATE_ID, USER_ID } from "../../key";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import trackValue from "../../Helpers/trackChange";
import sentenceCase from "../../Helpers/sentenceCase";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useDisableOnClick from "../../Helpers/useDisableOnClick";
import { SAVE_JOB_DATA, CLEAR_JOB_DATA } from "../../Logic/Redux/AllReducers";
import Tawk from "../Tawk";
import AccountProfile from "../../Components/AccountProfile";
// import User from "./User";
import Lottie from "react-lottie";
import useBreakpoint from "../../Helpers/useBreakpoint";
import emailjs from "emailjs-com";
import relax from "../../Images/lottie/relax.json";

const mobileQuestions = [
  {
    title: "What name do you choose to call this App?",
    isName: true,
  },
  {
    animation: "",
    title:
      "Do you have a logo or you would love us to create one for you based on your taste?",
  },
  {
    animation: "",
    title:
      "ARE THERE OTHER APPS THAT THE CLIENT LIKES THAT CAN BE USED AS INSPIRATION FOR HOW THIS NEW APP SHOULD LOOK?",
  },
  {
    animation: "",
    title: "WHO ARE THE TARGET USERS?",
    hint: "What problem is your app going to solve for them?",
  },
  {
    animation: "",
    title: "WHAT’S THE REAL DEADLINE?",
    hint: "Is it linked to any other activity (a big product launch) or tied into a seasonal campaign? Is it a situation of the case that the project should have started yesterday.",
  },
  {
    animation: "",
    title: "WHAT’S THE BUDGET?",
    hint: "Do you want to cater for a small sized audience? A medium sized audience? or A very large audience?, Are you intending on building the next Facebook or just a small start up?",
  },
  {
    animation: "",
    title: "WHO ARE THE KEY STAKEHOLDERS?",
    hint: "Is this whom Rexdreams is working with, or are there others that we need to know about? Who is the budget holder? Who is the Project Owner? Your contact, or someone else. What are the decision making stages? Who need to be consulted at what stage to move from Ideation, to prototype, to build, to test, to release? Who is Rexdreams going to work with post launch? Is there a formal process here",
  },

  {
    animation: "",
    title: "How can we get in touch with you frequently? ",
    hint: "please provide a valid means of communication. Various phone number(s) may do.",
  },
  {
    animation: "",
    title: "Please itemize the various functions of the User's App",
    hint: `The one to be placed to the public (AppStore or PlayStore)`,
  },
  {
    animation: "",
    title: "Please itemize the various functions of the Admin's App",
    hint: `The one that won't be placed to the public (AppStore or PlayStore), where you control your users and see all information`,
  },

  {
    animation: "",
    title: "WHAT IS THE MONETIZATION STRATEGY FOR THE APP?",
    hint: `If it’s designed to increase revenue for the business, how is it going to do that? in app purchases?, subscription model?, will features be unlocked with staged payment?, is a physical or virtual product being delivered after payment (so do you need logistic tracking)?, will there be in app advertising to monetize traffic through the app?`,
  },
  {
    animation: "",
    title: "HOW WILL THEY BUY?",
    hint: `Different from monetization. How do they physically pay for the product or service within the app, credit card, biometric payment (ApplePay and the like`,
  },
];
const websiteQuestions = [
  {
    animation: "",
    title: "What name do you choose to call this Website?",
    isName: true,
    hint: "Attach with '.com', '.ng', '.tech' or any domain extension you so desire",
  },
  {
    animation: "",
    title:
      "Have you already purchased this domain or you would love us to do that for you?",
  },
  {
    animation: "",
    title:
      "Do you have a logo or you would love us to create one for you based on your taste?",
  },
  { animation: "", title: "Describe your business in a few sentences." },
  {
    animation: "",
    title: "Which of these best describes the kind of website you want?",
    options: [
      {
        name: "An E-commerce website(Online market)",
      },
      {
        name: "A school website",
      },
      {
        name: "A company website",
      },
      {
        name: "A blog",
      },
      {
        name: "I have something else in mind",
      },
    ],
  },
  { animation: "", title: "What specific features do you want on the site?" },

  {
    animation: "",
    title: "How can we get in touch with you frequently?",
    hint: "Please provide a valid means of communication. Various phone number(s) may do.",
  },
  {
    animation: "",
    title:
      "Where do you reside currently at the time of placing an order to this digitized product?",
  },
];
const rootQuestions = {
  title: "How can Rexdreams help you?",
  options: [
    {
      name: "I want to create a website",
      next: websiteQuestions,
    },
    {
      name: "I want to create a mobile application",
      next: mobileQuestions,
    },
  ],
};
//website questions

const useStyles = makeStyles((theme) => {
  return {
    optionRoot: {
      width: "360px",
      maxWidth: "90vw",
      flexGrow: 0,
      margin: theme.spacing(2),
      "& .MuiCardActionArea-root": {
        padding: theme.spacing(2),
        height: "100%",
      },
    },
    optionHeader: {
      margin: theme.spacing(2, 0, 2),
    },
    userPageRoot: {
      background: theme.palette.background.contrast,
    },
  };
});
function SubmitJob({ user, onSubmit, isWebsite, store }) {
  const [extraData, setExtraData] = useState("");
  const [anchorEl, setAnchorEl] = useState("");
  const dispatch = useDispatch();
  const [, submitData] = useDisableOnClick(function () {
    const title = sentenceCase(
      store[0].answer.replace("I want to create a ", "")
    );
    const name = store[1].answer || "Unnamed";
    return submitJob({
      user,
      title: `${name} - ${title}`,
      description: store.slice(1),
    }).then(function () {
      let message = `${
        auth.currentUser.displayName
      } just created a job he/she might immediately want you to attend to on Rexdreams. Here are the job description ${store.map(
        (all) => {
          return (
            <>
              <p>{all.question}</p>
              <p>{all.answer}</p>
              <br />
            </>
          );
        }
      )}`;
      let from_name = auth.currentUser.displayName;
      let reply_to = auth.currentUser.email;
      let to_name = "Rexdreams";
      let templateParams = {
        from_name,
        reply_to,
        to_name,
        message,
      };
      emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, USER_ID);
      dispatch({ type: CLEAR_JOB_DATA });
    });
  });
  const buttonRef = useRef();
  useEffect(function () {
    setAnchorEl(buttonRef.current);
  }, []);
  const showPopup = function () {
    setPopupOpen(true);
  };
  const [popupOpen, setPopupOpen] = useState(false);
  const handlePopupClose = function () {
    setPopupOpen(false);
  };
  return (
    <>
      <Typography paragraph style={{ marginTop: "16px" }}>
        Thank you for your detailed description. Here's what we have so far? You
        can go back and alter any details.
        {store.map(({ question, answer }) => (
          <Typography paragraph>
            {`Question: ${question}`}
            <br /> {`Answer: ${answer || "No answer."}`}
          </Typography>
        ))}
      </Typography>
      <Typography paragraph>
        You can add any other specific information below.
      </Typography>
      <TextField
        multiline
        fullWidth
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "600px",
        }}
        rows={5}
        value={extraData}
        onChange={trackValue(setExtraData)}
        rowsMax={9}
        variant="filled"
      ></TextField>
      <Popover
        open={popupOpen}
        onClose={handlePopupClose}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        transformOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Box p={2}>
          <Typography paragraph>
            By clicking on I AGREE AND SUBMIT, I acknowledge that I have read,
            understood and agreed to the{" "}
            <NavLink to="/TC">terms and conditions</NavLink> provided by
            Rexdreams.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={submitData}
            style={{ display: "block", margin: "16px auto" }}
          >
            I AGREE AND SUBMIT
          </Button>
        </Box>
      </Popover>
      <Button
        variant="contained"
        color="primary"
        onClick={showPopup}
        ref={buttonRef}
        style={{ display: "block", margin: "16px auto" }}
      >
        Submit
      </Button>
    </>
  );
}
export const CreateJob = ({ user, onSubmit }) => {
  Tawk();
  const savedState = useSelector((state) => state.jobData);
  const dispatch = useDispatch();
  const saveState = () => {
    dispatch({
      type: SAVE_JOB_DATA,
      payload: { index: index, isWebsite: isWebsite, answers: answers.current },
    });
  };
  let index = savedState ? savedState.index : -1;
  const setIndex = (i) => {
    index = i;
    saveState();
  };
  let isWebsite = savedState ? savedState.isWebsite : false;
  const setIsWebsite = (e) => {
    isWebsite = e;
  };
  const answers = {
    current: savedState
      ? savedState.answers
      : {
          websiteAnswers: [],
          mobileAnswers: [],
        },
  };
  const store = isWebsite
    ? answers.current.websiteAnswers
    : answers.current.mobileAnswers;
  const [answer, setAnswer] = useState(store[index + 1]?.answer || "");

  const question =
    index < 0
      ? rootQuestions
      : isWebsite
      ? websiteQuestions[index]
      : mobileQuestions[index];
  const classes = useStyles();
  const advance = function (i) {
    return function () {
      if (index < 0) {
        setIsWebsite(!i);
      }
      //TODO: should not modify store
      //should be immutable
      const store = isWebsite
        ? answers.current.websiteAnswers
        : answers.current.mobileAnswers;
      store[index + 1] = {
        question: sentenceCase(question.title),
        answer: question.options ? question.options[i].name : answer,
      };
      setAnswer(store[index + 2]?.answer || "");
      setIndex(index + 1);
    };
  };

  const goBack = function () {
    const store = isWebsite
      ? answers.current.websiteAnswers
      : answers.current.mobileAnswers;

    setAnswer(store[index]?.answer || "");
    setIndex(index - 1);
  };
  const isMobile = useBreakpoint();
  return (
    <Box p={4} className={classes.userPageRoot}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box mb={2}>
          <Typography variant="h5">{`Welcome ${
            user.displayName || user.email
          }, we are happy to be of help to you.`}</Typography>
        </Box>
        <AccountProfile />
      </div>
      {index > -1 ? (
        <Button variant="outlined" color="secondary" onClick={goBack}>
          Previous
        </Button>
      ) : undefined}
      {question === undefined ? (
        <SubmitJob
          {...{
            user,
            onSubmit,
            isWebsite,
            store: isWebsite
              ? answers.current.websiteAnswers
              : answers.current.mobileAnswers,
          }}
        />
      ) : question.options ? (
        <>
          <Typography className={classes.optionHeader} variant="h4">
            {question.title}
          </Typography>
          <Box
            display="flex"
            flexWrap={"wrap"}
            justifyContent="center"
            align="center"
          >
            {question.options.map((e, i) => {
              return (
                <Card key={e.name} className={classes.optionRoot}>
                  <CardActionArea onClick={advance(i)}>
                    <Typography>{e.name}</Typography>
                  </CardActionArea>
                </Card>
              );
            })}
          </Box>
        </>
      ) : (
        <>
          <Box
            m={2}
            mb={8}
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              display="block"
              data-aos="fade"
              data-aos-duration={600}
              style={{
                maxWidth: "50%",
                width: "300px",
                minWidth: isMobile ? "100vw" : "360px",
                flexGrow: 1,
                margin: "0 50px",
              }}
            >
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: relax,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMidslice",
                  },
                }}
              ></Lottie>
            </Box>
            <>
              <Typography className={classes.optionHeader} variant="h4">
                {sentenceCase(question.title)}
              </Typography>

              {question.hint ? (
                <Typography
                  className={classes.optionHeader}
                  style={{ minHeight: "2em" }}
                  variant="h6"
                >
                  <div style={{ fontWeight: "bold" }}>Hint:</div>
                  {question.hint}
                </Typography>
              ) : null}
            </>
          </Box>
          <TextField
            multiline
            fullWidth
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              maxWidth: "600px",
            }}
            rows={5}
            value={answer}
            defaultValue={""}
            onChange={trackValue(setAnswer)}
            rowsMax={9}
            variant="filled"
          ></TextField>

          <Button
            variant="contained"
            color="primary"
            onClick={advance()}
            style={{ display: "block", margin: "16px auto" }}
          >
            Next
          </Button>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <p style={{ color: "yellow", fontWeight: "bold" }}>
              For clarity to a question you don't understand, use the chat icon
              below before hitting <b>SKIP</b>
            </p>

            <Button
              variant="contained"
              onClick={advance()}
              style={{
                display: "block",
                margin: "16px auto",
                background: "red",
                color: "white",
              }}
            >
              Skip
            </Button>
          </div>
        </>
      )}
      {/* <Button
        variant="contained"
        onClick={User()}
        style={{
          display: "block",
          margin: "16px auto",
          background: "red",
          color: "white",
        }}
      >
        Return Back
      </Button> */}
    </Box>
  );
};
export default CreateJob;
