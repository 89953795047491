import { query, where, collection, getDocs } from "firebase/firestore";
import store from "../Redux/Store";
import { JOB_CHANGED } from "../Redux/AllReducers";
import { firestore } from "./Init";
import { useUser } from "./Auth";
import { useSelector } from "react-redux";
import { addFirestore, getDataFromSnapshot } from "./ActionCreators";
import { STATUS_SUBMITTED } from "./JobStatus";
import useCancellableState from "../../Helpers/cancellable";
import { useEffect } from "react";
const JOBS_COLLECTION = "jobs";
/**
 * Chose a simple scalable structure for database
 *  jobs: {
 *    [jobID]:{//encrypted
 *       userId: string,
 *       title: string,
 *       description: string
 *    }
 *  }
 * }
 */

export function useListedJobs() {
  const user = useUser();
  const [jobs, setJobs] = useCancellableState(undefined);
  const refreshId = useSelector((state) => state.jobCounter);
  useEffect(
    function () {
      if (!user) return;
      listJobs({ user }).then(function (jobs) {
        setJobs(jobs);
      });
    },
    [user, setJobs, refreshId]
  );
  return jobs;
}
export async function listJobs({ user }) {
  return getDataFromSnapshot(
    await getDocs(
      query(
        collection(firestore, JOBS_COLLECTION),
        where("userId", "==", user.uid)
      )
    )
  );
}

export async function submitJob({ user, description, title }) {
  await addFirestore(JOBS_COLLECTION, {
    userId: user.uid,
    body: {
      description,
      title,
      paid: false,
      amount: null,
      dateCreated: new Date().getTime(),
      status: STATUS_SUBMITTED,
    },
  });
  store.dispatch({
    type: JOB_CHANGED,
  });
}
