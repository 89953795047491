import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import IconButton from "@material-ui/core/IconButton";
import { sendMessage } from "../../Logic/Firebase/Messages";
import trackChange from "../../Helpers/trackChange";
import { useUser } from "../../Logic/Firebase/Auth";
import { Button, ButtonBase } from "@material-ui/core";
import { AddAPhoto } from "@material-ui/icons";
import useDisableOnClick from "../../Helpers/useDisableOnClick";
const useStyles = makeStyles((theme) =>
  createStyles({
    wrapForm: {
      display: "flex",
      justifyContent: "center",
      width: "95%",
      padding: theme.spacing(1, 1, 2),
    },
    wrapText: {
      width: "100%",
      marginRight: theme.spacing(1),
    },
    fileInput: {
      position: "absolute",
      opacity: 0,
      maxWidth: "5px",
      maxHeight: "5px",
    },
    button: {},
  })
);

export const TextInput = ({ onSend }) => {
  const classes = useStyles();
  const [content, setContent] = useState();
  const user = useUser();
  const [pending, createAndSendMessage] = useDisableOnClick(function () {
    return sendMessage({ sender: user, title: "User Message", content }).then(
      () => {
        setContent("");
        onSend();
      }
    );
  }, true);

  const previewAndSendImage = function (ev) {
    const files = ev.target.files;
    if (files.length === 0) {
      return;
    }
    const image = files[0];
    switch (image.type) {
      case "image/apng":
      case "image/bmp":
      case "image/gif":
      case "image/jpeg":
      case "image/pjpeg":
      case "image/png":
      case "image/svg+xml":
      case "image/tiff":
      case "image/webp":
      case "image/x-icon":
        //allow
        break;
      default:
        return;
    }
    return sendMessage({ sender: user, image, title: "User Message" }).then(
      onSend
    );
  };
  return (
    <>
      <div className={classes.wrapForm} noValidate autoComplete="off">
        <TextField
          id="standard-text"
          label="Type message"
          value={content}
          defaultValue=""
          multiline
          onChange={trackChange(setContent)}
          className={classes.wrapText}
          //margin="normal"
        />
        <span style={{ position: "relative", marginRight: "5px" }}>
          <input
            type="file"
            className={classes.fileInput}
            accept="image/*"
            onChange={previewAndSendImage}
            id="Chat-pickImage"
          />
          <label htmlFor="Chat-pickImage">
            <IconButton color="primary" component="span">
              <AddAPhoto />
            </IconButton>
          </label>
        </span>
        <Button
          variant="contained"
          color="primary"
          disabled={!content}
          style={{
            borderRadius: "50%",
            minWidth: "48px",
            height: "48px",
            padding: 0,
          }}
          onClick={createAndSendMessage}
          className={classes.button}
        >
          <SendIcon />
        </Button>
      </div>
    </>
  );
};
