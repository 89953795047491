import { Button, Popover, Typography } from "@material-ui/core";
import { useUnreadMessages } from "../../Logic/Firebase/Messages";
import Loading from "../../Components/Loading";
import useBreakpoint from "../../Helpers/useBreakpoint";

import { makeStyles } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import { useHistory } from "react-router";
/**
 * @typedef {import("../../Logic/Firebase/Messages").Message} Message
 * @param {Message} params
 */

const useStyles = makeStyles(function (theme) {
  return {
    messageCard: {
      padding: "20px",
      minWidth: "300px",
      width: "50vw",
      maxWidth: "600px",
      minHeight: "150px",
      maxHeight: "600px",
    },
    placeholderText: {
      textAlign: "center",
      color: theme.palette.text.disabled,
      margin: theme.spacing(6, 0),
    },
  };
});
const MessageItem = function ({
  senderId,
  receiverId,
  body: { content, status, title, replyingTo, timeCreated },
}) {
  return (
    <div>
      <Typography>{title}</Typography>
      <Typography paragraph>{content}</Typography>
    </div>
  );
};
export default function MessageCard({ anchorEl, id, onClose }) {
  const history = useHistory();
  const gotoMessages = function () {
    history.push("/Chat");
  };

  const unreadMessages = useUnreadMessages();
  const isMenuOpen = Boolean(anchorEl);
  const classes = useStyles();
  const isSmallScreen = useBreakpoint();
  const position = isSmallScreen
    ? { vertical: "center", horizontal: "center" }
    : { vertical: "top", horizontal: "right" };

  return (
    <Popover
      anchorEl={isSmallScreen || anchorEl === true ? null : anchorEl}
      anchorOrigin={position}
      id={id}
      keepMounted
      transformOrigin={position}
      open={isMenuOpen}
      onClose={onClose}
      PaperProps={{
        className: classes.messageCard,
      }}
    >
      {unreadMessages === undefined ? (
        <Loading />
      ) : unreadMessages.size === 0 ? (
        <Typography className={classes.placeholderText} align="center">
          No unread messages
        </Typography>
      ) : (
        Array.from(unreadMessages).map(([key, message]) => (
          <MessageItem {...message} />
        ))
      )}

      <Button onClick={gotoMessages} variant="text" color="primary">
        View Messages
        <ChevronRight />
      </Button>
    </Popover>
  );
}
