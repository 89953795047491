import { useMemo } from "react";
import { useState, useEffect } from "react";
export default function usePromise(createPromise, deps) {
  const promise = useMemo(createPromise, deps);
  const [data, setData] = useState();
  useEffect(
    function () {
      let stale = false;
      promise.then(function (data) {
        if (!stale)
          setData(function () {
            return data;
          });
      });
      return function () {
        stale = true;
      };
    },
    [promise]
  );
  return data;
}
