//@refresh reset
import {
  storage as firebaseStorage,
  firestore as firebaseFirestore,
} from "./Init";
import {
  collection as table,
  deleteDoc,
  addDoc,
  doc,
  setDoc,
} from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { encryptData, decryptData } from "../Encrypt";
import { useEffect, useState } from "react";

/**
 *
 * @param {File} file
 * @param {string} id
 */

export const getDataFromSnapshot = (snapshot) => {
  try {
    let data = new Map();
    snapshot.forEach((doc) => {
      data.set(decryptData(doc.id), decryptData(doc.data()));
    });
    return data;
  } catch (error) {
    console.warn(error);
  }
};

export const uploadFileAndGetUrl = async (file, id) => {
  try {
    let extension = (file.name || "image").split(".").pop();
    const storageRef = ref(firebaseStorage, `files/${id}.${extension}`);

    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
  } catch (error) {
    console.warn(error);
  }
};

export const addFirestore = async (collection, data) => {
  try {
    data = encryptData(data);
    const docRef = table(firebaseFirestore, collection);
    await addDoc(docRef, data);
  } catch (error) {
    console.warn(error);
  }
};

export const setFirestore = async (collection, data, id) => {
  try {
    data = encryptData(data);
    id = encryptData(id);
    const docRef = doc(firebaseFirestore, collection, id);
    await setDoc(docRef, data);
  } catch (error) {
    console.warn(error);
  }
};
export const removeFirestore = async (collection, id) => {
  try {
    id = encryptData(id);
    const docRef = doc(firebaseFirestore, collection, id);
    await deleteDoc(docRef);
  } catch (error) {
    console.warn(error);
  }
};

/**
 * Allows multiple components to share a data source. The data source is setup when the first component is created and closed when the last component is destroyed.
 * @param {function} onSubscribe - called when the first subscriber is registered
 * @returns [useSubscription, subscribe, dispatch]
 */
export function createSubscription(onSubscribe) {
  const subscribers = [];
  let onUnsubscribe;
  let currentData;
  const subscribe = function (onNewData) {
    subscribers.push(onNewData);
    if (currentData !== undefined) onNewData(currentData);
    if (subscribers.length === 1) {
      onUnsubscribe = onSubscribe(dispatch);
    }
    return unsubscribe;
  };
  const unsubscribe = function (setData) {
    const index = subscribers.indexOf(setData);
    if (index > -1) {
      subscribers.splice(index, 1);
    }
    if (subscribers.length === 0) {
      if (onUnsubscribe) onUnsubscribe();
    }
  };
  const dispatch = function (data) {
    currentData = data;
    subscribers.forEach((e) => e(data));
  };
  return [
    function useSubscription() {
      const [data, setData] = useState(currentData);
      useEffect(function () {
        return subscribe(setData);
      }, []);
      return data;
    },
    subscribe,
    dispatch,
  ];
}
