import React from "react";
import Box from "@material-ui/core/Box";
import "./Home/Home.scss";

/**@param el {HTMLElement}
 * @param y {number}
 * @param h {number}
 * @param direction {(1|-1)}
 */
let theme = { appName: "ErrandBoss", email: "errandbossltd@gmail.com" };

function TermsAndConditions() {
  return (
    <Box
      data-baseweb="block"
      class="hb bp hc gx hd p-4"
      style={{ marginHorizontal: 20 }}
    >
      <div class="gx he h0">
        <div class="e9">
          <div class="bp">
            <div class="hf hg hh">
              <div class="hi hj b5 b6 b7 b8 hk hl hm hn ho hp hq hr hs ht hu hv hw hx hy hz i0 i1 i2 i3 i4 i5 i6 i7 i8 i9 ia ib ic id ie if ig ih ii ij ik il im in io ip iq ir is it iu iv iw ix iy iz j0 j1 j2 j3 j4 j5 j6 j7 j8 j9 ja jb jc jd je jf jg jh ji jj jk jl jm jn jo jp jq jr js jt ju jv jw jx jy jz k0 k1 k2 k3 k4 k5 k6 k7 k8 k9 ka kb kc kd">
                <div style={{ color: " #545454" }}>
                  Last modified: 20/09/2022
                </div>
                <br />
                <h4>
                  <a id="_ps20qd5rlci0"></a>
                  <strong>{theme.appName} Limited.</strong>
                </h4>
                <h1>
                  <a id="_odzjb8whv1bk"></a>Terms and Conditions
                </h1>
                <p>
                  <em>Last updated: October 16th, 2022</em>
                </p>
                <h2>
                  <a id="_vg09i4qatjmd"></a>1. Contractual Relationship
                </h2>
                <p>
                  These Terms of Use (“Terms”) govern the access or use by you,
                  an individual, from within any states in Nigeria of
                  applications, websites, content, products, and services (the
                  “Services”) made available by {theme.appName} Limited., a
                  private limited liability company established in the Nigeria,
                  having its offices at 1, PATRICK GEORGE STREET, KINGS ESTATE,
                  , EVIDENCE, EDO STATE, NIGERIA, registered at Abuja this 29th
                  day of June, 2022 under number RC1947692 (“{theme.appName}”).
                </p>
                <p>
                  PLEASE READ THESE TERMS CAREFULLY BEFORE ACCESSING OR USING
                  THE SERVICES.
                </p>
                <p>
                  Your access and use of the Services constitutes your agreement
                  to be bound by these Terms, which establishes a contractual
                  relationship between you and {theme.appName}. If you do not
                  agree to these Terms, you should not access or use the
                  Services. These Terms expressly supersede prior agreements or
                  arrangements with you. {theme.appName} may immediately
                  terminate these Terms or any Services with respect to you, or
                  generally cease offering or deny access to the Services or any
                  portion thereof, at any time for any reason.
                </p>
                <p>
                  Supplemental terms may apply to certain Services, such as
                  policies for a particular event, activity or promotion, and
                  such supplemental terms will be disclosed to you in connection
                  with the applicable Services. Supplemental terms are in
                  addition to, and shall be deemed a part of, the Terms for the
                  purposes of the applicable Services. Supplemental terms shall
                  prevail over these Terms in the event of a conflict with
                  respect to the applicable Services.
                </p>
                <p>
                  {theme.appName} may amend the Terms related to the Services
                  from time to time. Amendments will be effective upon{" "}
                  {theme.appName}'s posting of such updated Terms at this
                  location or the amended policies or supplemental terms on the
                  applicable Service. Your continued access or use of the
                  Services after such posting constitutes your consent to be
                  bound by the Terms, as amended.
                </p>
                <p>
                  {theme.appName} may provide to a claims processor or an
                  insurer any necessary information (including your contact
                  information) if there is a complaint, dispute or conflict,
                  which may include an accident, involving you and a Third Party
                  Provider (including a transportation network company driver)
                  and such information or data is necessary to resolve the
                  complaint, dispute or conflict.
                </p>
                <h2>
                  <a id="_qy2mg6mk3vg9"></a>2. The Services
                </h2>
                <p>
                  The Services constitute a technology platform that enables
                  users of {theme.appName}'s mobile applications or websites
                  provided as part of the Services (each, an “Application”) to
                  arrange and schedule logistics services with independent third
                  party providers of such services, including independent third
                  party logistics providers under agreement with {theme.appName}{" "}
                  or certain of {theme.appName}'s affiliates (“Third Party
                  Providers”). Unless otherwise agreed by {theme.appName} in a
                  separate written agreement with you, the Services are made
                  available solely for your personal and commercial use. YOU
                  ACKNOWLEDGE THAT {theme.appName} DOES NOT PROVIDE LOGISTICS
                  SERVICES AND THAT ALL SUCH LOGISTICS SERVICES ARE PROVIDED BY
                  INDEPENDENT THIRD PARTY CONTRACTORS WHO ARE NOT EMPLOYED BY
                  {theme.appName} OR ANY OF ITS AFFILIATES.
                </p>
                <h5>
                  <a id="_wldbu91fbx47"></a>
                  <strong>License.</strong>
                </h5>
                <p>
                  Subject to your compliance with these Terms, {theme.appName}{" "}
                  grants you a limited, non-exclusive, non-sublicensable,
                  revocable, non-transferrable license to: (i) access and use
                  the Applications on your personal device solely in connection
                  with your use of the Services; and (ii) access and use any
                  content, information and related materials that may be made
                  available through the Services, in each case solely for your
                  personal or commercial use. Any rights not expressly granted
                  herein are reserved by {theme.appName} and {theme.appName}'s
                  licensors.
                </p>
                <h5>
                  <a id="_4pwmt5kgk8m5"></a>
                  <strong>Restrictions.</strong>
                </h5>
                <p>
                  You may not: (i) remove any copyright, trademark or other
                  proprietary notices from any portion of the Services; (ii)
                  reproduce, modify, prepare derivative works based upon,
                  distribute, license, lease, sell, resell, transfer, publicly
                  display, publicly perform, transmit, stream, broadcast or
                  otherwise exploit the Services except as expressly permitted
                  by {theme.appName}; (iii) decompile, reverse engineer or
                  disassemble the Services except as may be permitted by
                  applicable law; (iv) link to, mirror or frame any portion of
                  the Services; (v) cause or launch any programs or scripts for
                  the purpose of scraping, indexing, surveying, or otherwise
                  data mining any portion of the Services or unduly burdening or
                  hindering the operation and/or functionality of any aspect of
                  the Services; or (vi) attempt to gain unauthorized access to
                  or impair any aspect of the Services or its related systems or
                  networks.
                </p>
                <h5>
                  <a id="_j0x5z7o8cyaa"></a>
                  <strong>Provision of the Services.</strong>
                </h5>
                <p>
                  You acknowledge that portions of the Services may be made
                  available under {theme.appName}'s various brands or request
                  options associated with logistics, including the logistic
                  request brands currently referred to as {theme.appName},”. You
                  also acknowledge that the Services may be made available under
                  such brands or request options by or in connection with: (i)
                  certain of {theme.appName}'s subsidiaries and affiliates; or
                  (ii) independent Third Party Providers, including logistics
                  network company drivers, logistics charter permit holders or
                  holders of similar logistics permits, authorizations or
                  licenses.
                </p>
                <h5>
                  <a id="_74ti9bsiwcqf"></a>
                  <strong>Third Party Services and Content.</strong>
                </h5>
                <p>
                  The Services may be made available or accessed in connection
                  with third party services and content (including advertising)
                  that {theme.appName} does not control. You acknowledge that
                  different terms of use and privacy policies may apply to your
                  use of such third party services and content. {theme.appName}{" "}
                  does not endorse such third party services and content and in
                  no event shall {theme.appName} be responsible or liable for
                  any products or services of such third party providers.
                  Additionally, Apple Inc., Google, Inc., Microsoft Corporation
                  or BlackBerry Limited and/or their applicable international
                  subsidiaries and affiliates will be third-party beneficiaries
                  to this contract if you access the Services using Applications
                  developed for Apple iOS, Android, Microsoft Windows, or
                  Blackberry-powered mobile devices, respectively. These third
                  party beneficiaries are not parties to this contract and are
                  not responsible for the provision or support of the Services
                  in any manner. Your access to the Services using these devices
                  is subject to terms set forth in the applicable third party
                  beneficiary’s terms of service.
                </p>
                <h5>
                  <a id="_w3c68ya5uatn"></a>
                  <strong>Ownership.</strong>
                </h5>
                <p>
                  The Services and all rights therein are and shall remain
                  {" " + theme.appName}'s property or the property of{" "}
                  {theme.appName}
                  's licensors. Neither these Terms nor your use of the Services
                  convey or grant to you any rights: (i) in or related to the
                  Services except for the limited license granted above; or (ii)
                  to use or reference in any manner {theme.appName}'s company
                  names, logos, product and service names, trademarks or
                  services marks or those of {theme.appName}'s licensors.
                </p>
                <h2>
                  <a id="_cclu8c59ie58"></a>3. Your Use of the Services
                </h2>
                <h5>
                  <a id="_ay1mdlqcjcan"></a>
                  <strong>User Accounts.</strong>
                </h5>
                <p>
                  In order to use most aspects of the Services, you must
                  register for and maintain an active personal user Services
                  account (“
                  <em>Account</em>”). You must be at least 18 years of age, or
                  the age of legal majority in your jurisdiction (if different
                  than 18), to obtain an Account. Account registration requires
                  you to submit to {theme.appName} certain personal information,
                  such as your name, address, mobile phone number and age, as
                  well as at least one valid payment method (either a credit
                  card or accepted payment partner) and government ID (only for
                  riders). You agree to maintain accurate, complete, and
                  up-to-date information in your Account. Your failure to
                  maintain accurate, complete, and up-to-date Account
                  information, including having an invalid or expired payment
                  method on file, may result in your inability to access and use
                  the Services or {theme.appName}'s termination of these Terms
                  with you. You are responsible for all activity that occurs
                  under your Account, and you agree to maintain the security and
                  secrecy of your Account username and password at all times.
                  Unless otherwise permitted by {theme.appName} in writing, you
                  may only possess one Account.
                </p>
                <h5>
                  <a id="_5mn2owtdo3u4"></a>
                  <strong>User Requirements and Conduct.</strong>
                </h5>
                <p>
                  The Service is not available for use by persons under the age
                  of 18. You may not authorize third parties to use your
                  Account, and you may not allow persons under the age of 18 to
                  receive transportation or logistics services from Third Party
                  Providers unless they are accompanied by you. You may not
                  assign or otherwise transfer your Account to any other person
                  or entity. You agree to comply with all applicable laws when
                  using the Services, and you may only use the Services for
                  lawful purposes (e.g., no transport of unlawful or hazardous
                  materials). You will not, in your use of the Services, cause
                  nuisance, annoyance, inconvenience, or property damage,
                  whether to the Third Party Provider or any other party. In
                  certain instances you may be asked to provide proof of
                  identity to access or use the Services, and you agree that you
                  may be denied access to or use of the Services if you refuse
                  to provide proof of identity.
                </p>
                <h5>
                  <a id="_oewnhrg70isj"></a>
                  <strong>Text Messaging.</strong>
                </h5>
                <p>
                  By creating an Account, you agree that the Services may send
                  you text (SMS) messages as part of the normal business
                  operation of your use of the Services. You may opt-out of
                  receiving text (SMS) messages from {theme.appName} at any time
                  by following the directions found at support button found in
                  the app. You acknowledge that opting out of receiving text
                  (SMS) messages may impact your use of the Services.
                </p>
                <h5>
                  <a id="_aqla257cky01"></a>
                  <strong>Promotional Codes.</strong>
                </h5>
                <p>
                  {theme.appName} may, in {theme.appName}'s sole discretion,
                  create promotional codes that may be redeemed for Account
                  credit, or other features or benefits related to the Services
                  and/or a Third Party Provider’s services, subject to any
                  additional terms that {theme.appName} establishes on a per
                  promotional code basis (“Promo Codes”). You agree that Promo
                  Codes: (i) must be used for the intended audience and purpose,
                  and in a lawful manner; (ii) may not be duplicated, sold or
                  transferred in any manner, or made available to the general
                  public (whether posted to a public form or otherwise), unless
                  expressly permitted by {theme.appName}; (iii) may be disabled
                  by {theme.appName} at any time for any reason without
                  liability to {theme.appName}; (iv) may only be used pursuant
                  to the specific terms that {theme.appName}
                  establishes for such Promo Code; (v) are not valid for cash;
                  and (vi) may expire prior to your use. {theme.appName}{" "}
                  reserves the right to withhold or deduct credits or other
                  features or benefits obtained through the use of Promo Codes
                  by you or any other user in the event that {theme.appName}{" "}
                  determines or believes that the use or redemption of the Promo
                  Code was in error, fraudulent, illegal, or in violation of the
                  applicable Promo Code terms or these Terms.
                </p>
                <h5>
                  <a id="_s7jkllrv36e"></a>
                  <strong>User Provided Content.</strong>
                </h5>
                <p>
                  {theme.appName} may, in {theme.appName + "'s"} sole
                  discretion, permit you from time to time to submit, upload,
                  publish or otherwise make available to {theme.appName} through
                  the Services textual, audio, and/or visual content and
                  information, including commentary and feedback related to the
                  Services, initiation of support requests, and submission of
                  entries for competitions and promotions (“User Content”). Any
                  User Content provided by you remains your property. However,
                  by providing User Content to
                  {theme.appName}, you grant {theme.appName} a worldwide,
                  perpetual, irrevocable, transferrable, royalty-free license,
                  with the right to sublicense, to use, copy, modify, create
                  derivative works of, distribute, publicly display, publicly
                  perform, and otherwise exploit in any manner such User Content
                  in all formats and distribution channels now known or
                  hereafter devised (including in connection with the Services
                  and {theme.appName + "'s"} business and on third-party sites
                  and services), without further notice to or consent from you,
                  and without the requirement of payment to you or any other
                  person or entity.
                </p>
                <p>
                  You represent and warrant that: (i) you either are the sole
                  and exclusive owner of all User Content or you have all
                  rights, licenses, consents and releases necessary to grant
                  {theme.appName} the license to the User Content as set forth
                  above; and (ii) neither the User Content nor your submission,
                  uploading, publishing or otherwise making available of such
                  User Content nor {theme.appName + "'s"} use of the User
                  Content as permitted herein will infringe, misappropriate or
                  violate a third party’s intellectual property or proprietary
                  rights, or rights of publicity or privacy, or result in the
                  violation of any applicable law or regulation.
                </p>
                <p>
                  You agree to not provide User Content that is defamatory,
                  libelous, hateful, violent, obscene, pornographic, unlawful,
                  or otherwise offensive, as determined by {theme.appName} in
                  its sole discretion, whether or not such material may be
                  protected by law. {theme.appName} may, but shall not be
                  obligated to, review, monitor, or remove User Content, at
                  {theme.appName + "'s"} sole discretion and at any time and for
                  any reason, without notice to you.
                </p>
                <h5>
                  <a id="_hbhurtf0h64b"></a>
                  <strong>Network Access and Devices.</strong>
                </h5>
                <p>
                  You are responsible for obtaining the data network access
                  necessary to use the Services. Your mobile network’s data and
                  messaging rates and fees may apply if you access or use the
                  Services from a wireless-enabled device and you shall be
                  responsible for such rates and fees. You are responsible for
                  acquiring and updating compatible hardware or devices
                  necessary to access and use the Services and Applications and
                  any updates thereto. {theme.appName} does not guarantee that
                  the Services, or any portion thereof, will function on any
                  particular hardware or devices. In addition, the Services may
                  be subject to malfunctions and delays inherent in the use of
                  the Internet and electronic communications.
                </p>
                <h2>
                  <a id="_scycmfgwjdo7"></a>4. Payment
                </h2>
                <p>
                  You understand that use of the Services may result in charges
                  to you for the services or goods you receive from a Third
                  Party Provider (“Charges”). After you have received services
                  or goods obtained through your use of the Service,{" "}
                  {theme.appName}
                  will facilitate your payment of the applicable Charges on
                  behalf of the Third Party Provider as such Third Party
                  Provider’s limited payment collection agent. Payment of the
                  Charges in such manner shall be considered the same as payment
                  made directly by you to the Third Party Provider. Charges may
                  include other applicable fees, tolls, and/or surcharges
                  including a booking fee, national, provincial and municipal
                  tolls, airport surcharges and processing fees for split
                  payments, and will be inclusive of applicable taxes where
                  required by law. Charges paid by you are final and
                  non-refundable, unless otherwise determined by {theme.appName}
                  . You retain the right to request lower Charges from a Third
                  Party Provider for services or goods received by you from such
                  Third Party Provider at the time you receive such services or
                  goods.
                  {theme.appName} will respond accordingly to any request from a
                  Third Party Provider to modify the Charges for a particular
                  service or good.
                </p>
                <p>
                  All Charges are due immediately and payment will be
                  facilitated by {theme.appName} using the preferred payment
                  method designated in your Account, after which {theme.appName}{" "}
                  will send you a receipt by email. If your primary Account
                  payment method is determined to be expired, invalid or
                  otherwise not able to be charged, you agree that{" "}
                  {theme.appName} may, as the Third Party Provider’s limited
                  payment collection agent, use a secondary payment method in
                  your Account, if available.
                </p>
                <p>
                  As between you and {theme.appName}, {theme.appName} reserves
                  the right to establish, remove and/or revise Charges for any
                  or all services or goods obtained through the use of the
                  Services at any time in {theme.appName + "'s"} sole
                  discretion. Further, you acknowledge and agree that Charges
                  applicable in certain geographical areas may increase
                  substantially during times of high demand. {theme.appName}{" "}
                  will use reasonable efforts to inform you of Charges that may
                  apply, provided that you will be responsible for Charges
                  incurred under your Account regardless of your awareness of
                  such Charges or the amounts thereof.
                  {theme.appName} may from time to time provide certain users
                  with promotional offers and discounts that may result in
                  different amounts charged for the same or similar services or
                  goods obtained through the use of the Services, and you agree
                  that such promotional offers and discounts, unless also made
                  available to you, shall have no bearing on your use of the
                  Services or the Charges applied to you. You may elect to
                  cancel your request for services or goods from a Third Party
                  Provider at any time prior to such Third Party Provider’s
                  arrival, in which case you may be charged a cancellation fee.
                </p>
                <p>
                  This payment structure is intended to fully compensate the
                  Third Party Provider for the services or goods provided.
                  Except with respect to taxicab transportation services
                  requested through the Application, {theme.appName} does not
                  designate any portion of your payment as a tip or gratuity to
                  the Third Party Provider. Any representation by{" "}
                  {theme.appName} (on {theme.appName + "'s"} website, in the
                  Application, or in {theme.appName + "'s"} marketing materials)
                  to the effect that tipping is “voluntary,” “not required,”
                  and/or “included” in the payments you make for services or
                  goods provided is not intended to suggest that {theme.appName}{" "}
                  provides any additional amounts, beyond those described above,
                  to the Third Party Provider. You understand and agree that,
                  while you are free to provide additional payment as a gratuity
                  to any Third Party Provider who provides you with services or
                  goods obtained through the Service, you are under no
                  obligation to do so. Gratuities are voluntary. After you have
                  received services or goods obtained through the Service, you
                  will have the opportunity to rate your experience and leave
                  additional feedback about your Third Party Provider.
                </p>
                <h5>
                  <a id="_ct0itufxu5bb"></a>
                  <strong>Repair or Cleaning Fees.</strong>
                </h5>
                <p>
                  You shall be responsible for the cost of repair for damage to,
                  or necessary cleaning of, Third Party Provider vehicles and
                  property resulting from use of the Services under your Account
                  in excess of normal “wear and tear” damages and necessary
                  cleaning (“Repair or Cleaning”). In the event that a Third
                  Party Provider reports the need for Repair or Cleaning, and
                  such Repair or Cleaning request is verified by {theme.appName}{" "}
                  in {theme.appName + "'s"} reasonable discretion,{" "}
                  {theme.appName} reserves the right to facilitate payment for
                  the reasonable cost of such Repair or Cleaning on behalf of
                  the Third Party Provider using your payment method designated
                  in your Account. Such amounts will be transferred by{" "}
                  {theme.appName} to the applicable Third Party Provider and are
                  non-refundable.
                </p>
                <h2>
                  <a id="_a4uq58jd439d"></a>5. Disclaimers; Limitation of
                  Liability; Indemnity.
                </h2>
                <h5>
                  <a id="_svn4q19mv6hs"></a>
                  <strong>DISCLAIMER.</strong>
                </h5>
                <p>
                  THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE.”
                  {theme.appName} DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES,
                  EXPRESS, IMPLIED OR STATUTORY, NOT EXPRESSLY SET OUT IN THESE
                  TERMS, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY,
                  FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. IN
                  ADDITION, {theme.appName} MAKES NO REPRESENTATION, WARRANTY,
                  OR GUARANTEE REGARDING THE RELIABILITY, TIMELINESS, QUALITY,
                  SUITABILITY OR AVAILABILITY OF THE SERVICES OR ANY SERVICES OR
                  GOODS REQUESTED THROUGH THE USE OF THE SERVICES, OR THAT THE
                  SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. {theme.appName}{" "}
                  DOES NOT GUARANTEE THE QUALITY, SUITABILITY, SAFETY OR ABILITY
                  OF THIRD PARTY PROVIDERS. YOU AGREE THAT THE ENTIRE RISK
                  ARISING OUT OF YOUR USE OF THE SERVICES, AND ANY SERVICE OR
                  GOOD REQUESTED IN CONNECTION THEREWITH, REMAINS SOLELY WITH
                  YOU, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.
                </p>
                <h5>
                  <a id="_5lk0fj86fof9"></a>
                  <strong>LIMITATION OF LIABILITY.</strong>
                </h5>
                <p>
                  {theme.appName} SHALL NOT BE LIABLE FOR INDIRECT, INCIDENTAL,
                  SPECIAL, EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES,
                  INCLUDING LOST PROFITS, LOST DATA, PERSONAL INJURY OR PROPERTY
                  DAMAGE RELATED TO, IN CONNECTION WITH, OR OTHERWISE RESULTING
                  FROM ANY USE OF THE SERVICES, EVEN IF {theme.appName} HAS BEEN
                  ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. {theme.appName}{" "}
                  SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES
                  ARISING OUT OF: (i) YOUR USE OF OR RELIANCE ON THE SERVICES OR
                  YOUR INABILITY TO ACCESS OR USE THE SERVICES; OR (ii) ANY
                  TRANSACTION OR RELATIONSHIP BETWEEN YOU AND ANY THIRD PARTY
                  PROVIDER, EVEN IF {theme.appName} HAS BEEN ADVISED OF THE
                  POSSIBILITY OF SUCH DAMAGES. {theme.appName} SHALL NOT BE
                  LIABLE FOR DELAY OR FAILURE IN PERFORMANCE RESULTING FROM
                  CAUSES BEYOND ExprexPro’S REASONABLE CONTROL. YOU ACKNOWLEDGE
                  THAT THIRD PARTY TRANSPORTATION PROVIDERS PROVIDING
                  TRANSPORTATION SERVICES REQUESTED THROUGH SOME REQUEST BRANDS
                  MAY OFFER RIDESHARING OR PEER-TO-PEER TRANSPORTATION SERVICES
                  AND MAY NOT BE PROFESSIONALLY LICENSED OR PERMITTED. IN NO
                  EVENT SHALL ExprexPro’S TOTAL LIABILITY TO YOU IN CONNECTION
                  WITH THE SERVICES FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION
                  EXCEED TEN THOUSAND NAIRA (#10,000).
                </p>
                <p>
                  EXPREXPRO’S SERVICES MAY BE USED BY YOU TO REQUEST AND
                  SCHEDULE TRANSPORTATION, GOODS OR LOGISTICS SERVICES WITH
                  THIRD PARTY PROVIDERS, BUT YOU AGREE THAT {theme.appName} HAS
                  NO RESPONSIBILITY OR LIABILITY TO YOU RELATED TO ANY
                  TRANSPORTATION, GOODS OR LOGISTICS SERVICES PROVIDED TO YOU BY
                  THIRD PARTY PROVIDERS OTHER THAN AS EXPRESSLY SET FORTH IN
                  THESE TERMS.
                </p>
                <p>
                  THE LIMITATIONS AND DISCLAIMER IN THIS SECTION 5 DO NOT
                  PURPORT TO LIMIT LIABILITY OR ALTER YOUR RIGHTS AS A CONSUMER
                  THAT CANNOT BE EXCLUDED UNDER APPLICABLE LAW.
                </p>
                <h5>
                  <a id="_dsfqvel1dy4d"></a>
                  <strong>Indemnity.</strong>
                </h5>
                <p>
                  You agree to indemnify and hold {theme.appName} and its
                  officers, directors, employees and agents harmless from any
                  and all claims, demands, losses, liabilities, and expenses
                  (including attorneys’ fees) arising out of or in connection
                  with: (i) your use of the Services or services or goods
                  obtained through your use of the Services; (ii) your breach or
                  violation of any of these Terms; (iii) {theme.appName + "'s"}{" "}
                  use of your User Content; or (iv) your violation of the rights
                  of any third party, including Third Party Providers.
                </p>
                <h2>
                  <a id="_yfgqlz50sdxb"></a>6. Lost Property
                </h2>
                <p>
                  You understand and agree that it is your responsibility to
                  ensure that you give only goods to be dispatched to a Third
                  Party Provider when embarking. Should you do otherwise in the
                  vehicle of a Third Party Provider, the Third Party Provider
                  may hand over your property to you, {theme.appName} or to the
                  {theme.appName} local service entity.
                </p>
                <p>
                  Whilst you may expect Third Party Providers to hand over your
                  property to you, the offices of {theme.appName} or the{" "}
                  {theme.appName}
                  local service entity, {theme.appName} or the {theme.appName}{" "}
                  local service entity shall not be held liable in the event of
                  the Third Party Provider not handing over your property as
                  expected. Moreover, {theme.appName} or the {theme.appName}{" "}
                  local service entity shall not be liable for the loss or
                  damage to your property whilst it is in transit.
                </p>
                <p>
                  Whilst {theme.appName} or the {theme.appName} local service
                  entity will take reasonable steps to establish the owner of
                  property left in a Third Party Provider’s vehicle if returned
                  to the offices of {theme.appName} or the {theme.appName} local
                  service entity, when your property is in{" "}
                  {theme.appName + "'s"} or the {theme.appName} local service
                  entity’s possession, you understand and agree that: (i)
                  {theme.appName} or the {theme.appName} local service entity
                  will only keep your property in its possession for a maximum
                  period of three months from the date on which the Third Party
                  Provider handed your property to {theme.appName} or the{" "}
                  {theme.appName} local service entity; and (ii) should you fail
                  to collect your property from
                  {theme.appName} or the {theme.appName} local service entity
                  before the expiry of the three month period stipulated,{" "}
                  {theme.appName} or the
                  {theme.appName} local service entity will be entitled to deal
                  with your property as it deems fit and you shall have no claim
                  whatsoever against {theme.appName} or the {theme.appName}{" "}
                  local service entity in respect of your unclaimed property.
                </p>
                <h2>
                  <a id="_c886yng39um2"></a>7. Governing Law; Arbitration.
                </h2>
                <p>
                  Except as otherwise set forth in these Terms, these Terms
                  shall be exclusively governed by and construed in accordance
                  with the laws of The Nigerian Constitution under the COMPANIES
                  AND ALLIED MATTERS ACT 2020, excluding its rules on conflicts
                  of laws. Any dispute, conflict, claim or controversy arising
                  out of or broadly in connection with or relating to the
                  Services or these Terms, including those relating to its
                  validity, its construction or its enforceability (any
                  “Dispute”) shall be first mandatorily submitted to mediation
                  proceedings under the International Chamber of Commerce
                  Mediation Rules (“ICC Mediation Rules”). If such Dispute has
                  not been settled within sixty (60) days after a request for
                  mediation has been submitted under such ICC Mediation Rules,
                  such Dispute can be referred to and shall be exclusively and
                  finally resolved by arbitration under the Rules of Arbitration
                  of the International Chamber of Commerce (“ICC Arbitration
                  Rules”). The ICC Rules' Emergency Arbitrator provisions are
                  excluded. The Dispute shall be resolved by one (1) arbitrator
                  to be appointed in accordance with the ICC Rules. The place of
                  both mediation and arbitration shall be Lagos State High
                  Court(s), Nigeria, without prejudice to any rights you may
                  have under Article 18 of the Brussels I bis Regulation (OJ EU
                  2012 L351/1) and/or Article 6:236n of the Dutch Civil Code.
                  The language of the mediation and/or arbitration shall be
                  English, unless you do not speak English, in which case the
                  mediation and/or arbitration shall be conducted in both
                  English and your native language. The existence and content of
                  the mediation and arbitration proceedings, including documents
                  and briefs submitted by the parties, correspondence from and
                  to the International Chamber of Commerce, correspondence from
                  the mediator, and correspondence, orders and awards issued by
                  the sole arbitrator, shall remain strictly confidential and
                  shall not be disclosed to any third party without the express
                  written consent from the other party unless: (i) the
                  disclosure to the third party is reasonably required in the
                  context of conducting the mediation or arbitration
                  proceedings; and (ii) the third party agrees unconditionally
                  in writing to be bound by the confidentiality obligation
                  stipulated herein.
                </p>
                <h2>
                  <a id="_hpypkfff5jkl"></a>8. Other Provisions
                </h2>
                <h5>
                  <a id="_q2bfo0ai3m3t"></a>
                  <strong>Claims of Copyright Infringement.</strong>
                </h5>
                <p>
                  Claims of copyright infringement should be sent to{" "}
                  {theme.appName}'s designated agent. Please contact{" "}
                  {theme.appName}'s at {theme.email} for the designated address
                  and additional information.
                </p>
                <h5>
                  <a id="_j6r8oxm1om7c"></a>
                  <strong>Notice.</strong>
                </h5>
                <p>
                  {theme.appName} may give notice by means of a general notice
                  on the Services, electronic mail to your email address in your
                  Account, or by written communication sent to your address as
                  set forth in your Account. You may give notice to{" "}
                  {theme.appName} by written communication to {theme.appName}'s
                  address at 1, PATRICK GEORGE STREET, KINGS ESTATE, , EVIDENCE,
                  EDO STATE, NIGERIA.
                </p>
                <h5>
                  <a id="_ixcj6vqoj6cu"></a>
                  <strong>General.</strong>
                </h5>
                <p>
                  You may not assign or transfer these Terms in whole or in part
                  without {theme.appName + "'s"} prior written approval. You
                  give your approval to {theme.appName} for it to assign or
                  transfer these Terms in whole or in part, including to: (i) a
                  subsidiary or affiliate; (ii) an acquirer of{" "}
                  {theme.appName + "'s"} equity, business or assets; or (iii) a
                  successor by merger. No joint venture, partnership, employment
                  or agency relationship exists between you, {theme.appName} or
                  any Third Party Provider as a result of the contract between
                  you and {theme.appName} or use of the Services.
                </p>
                <p>
                  If any provision of these Terms is held to be illegal, invalid
                  or unenforceable, in whole or in part, under any law, such
                  provision or part thereof shall to that extent be deemed not
                  to form part of these Terms but the legality, validity and
                  enforceability of the other provisions in these Terms shall
                  not be affected. In that event, the parties shall replace the
                  illegal, invalid or unenforceable provision or part thereof
                  with a provision or part thereof that is legal, valid and
                  enforceable and that has, to the greatest extent possible, a
                  similar effect as the illegal, invalid or unenforceable
                  provision or part thereof, given the contents and purpose of
                  these Terms. These Terms constitute the entire agreement and
                  understanding of the parties with respect to its subject
                  matter and replaces and supersedes all prior or
                  contemporaneous agreements or undertakings regarding such
                  subject matter. In these Terms, the words “including” and
                  “include” mean “including, but not limited to.”
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}

export default TermsAndConditions;
