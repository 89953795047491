import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Grid from "@material-ui/core/Grid";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import "../Components/SetupAos";
import App from "../Screens/App/App";
import Typography from "@material-ui/core/Typography";
import Dreams from "../Images/Dreams.png";
import Us from "../Components/Us";
import Button from "@material-ui/core/Button";
import Tawk from "./Tawk";
import { useUser } from "../Logic/Firebase/Auth";
import Loading from "./Loading";

const theme = createMuiTheme({});

const useStyles = makeStyles((about) => ({
  hero: {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("Dreams.png")`,
    height: "500px",
    width: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#778899",
    fontSize: "6rem",
    fontFamily: "Verdana",
    fontWeight: 500,
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-end",
      fontSize: "4rem",
      backgroundPosition: "50% 0",
      backgroundSize: "150%",
    },
  },

  body: {
    paddingTop: theme.spacing(3),
  },

  title: {
    fontWeight: 600,
    paddingBottom: theme.spacing(3),
    fontStyle: "oblique",
    fontFamily: "Arial",
    color: "#778899",
    paddingTop: theme.spacing(3),
  },

  card: {
    maxWidth: "100%",
  },

  media: {
    height: 50,
  },

  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

function About() {
  const classes = useStyles();
  const theme = createMuiTheme();
  const [loading, setLoading] = useState(false);

  Tawk();
  return (
    <App>
      <div className="App">
        {loading ? (
          <Loading />
        ) : (
          <Box
            onLoadStart={() => setLoading(true)}
            onLoadEnd={() => setLoading(false)}
            className={classes.hero}
            p={2}
          >
            <Box>About Us</Box>
          </Box>
        )}
        <Container maxWidth="lg" className={classes.body}>
          <Typography
            variant="h4"
            className={classes.title}
            data-aos="zoom-in"
            align="center"
          >
            Who We Are
          </Typography>
          {/* flex  box */}
          <Us className="swipeContainer__child" />
          <Card
            data-aos="zoom-out-up"
            className="shadow bg-secondary"
            sx={{ maxWidth: 345 }}
          >
            <CardActionArea>
              <CardMedia
                component="img"
                sx={{ height: 140 }}
                image="Rdr.png"
                title="REXDREAMS"
              />
            </CardActionArea>
          </Card>

          <Typography
            variant="h4"
            className={classes.title}
            data-aos="zoom-in-up"
            //
            paddingTop="10"
          >
            What We Do
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <Card
                data-aos="zoom-in-up"
                sx={{ maxWidth: 345 }}
                className="shadow bg-secondary"
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    sx={{ height: 140 }}
                    image="Web.jpg"
                    title="WebApp"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      WebApp
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      We at REXDREAMS are passionate about building cost
                      effective and user-friendly native WebApps.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Card
                data-aos="zoom-in-up"
                sx={{ maxWidth: 345 }}
                className="shadow bg-secondary"
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    sx={{ height: 140 }}
                    image="Android.jpg"
                    title="Android"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Android
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      We at REXDREAMS are passionate about building cost
                      effective and user-friendly Android apps.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Card
                data-aos="fade-down"
                sx={{ maxWidth: 345 }}
                className="shadow bg-secondary"
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    alt="ios"
                    sx={{ height: 140 }}
                    image="Ios.jpg"
                    title="ios"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      ios
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      We at REXDREAMS are passionate about building cost
                      effective and user-friendly native ios apps
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>

          <Typography
            variant="h4"
            className={classes.title}
            data-aos="zoom-in"
            align="center"
          >
            What we offer
          </Typography>
          <Grid>
            <Card data-aos="zoom-out-up" className="shadow bg-secondary">
              <CardActionArea>
                <CardMedia
                  component="img"
                  sx={{ height: 140 }}
                  image="offer.png"
                  title="What We Offer"
                />
              </CardActionArea>
            </Card>
          </Grid>
        </Container>
      </div>
    </App>
  );
}

export default About;
