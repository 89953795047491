import React, { useRef, useState } from "react";

import "./Home/Home.scss";
import { useSwipeable } from "react-swipeable";
import App from "./App/App";
import "../Components/SetupAos";
import useAnimatedScroll from "../Helpers/animatedScroll";

/**@param el {HTMLElement}
 * @param y {number}
 * @param h {number}
 * @param direction {(1|-1)}
 */
const getTargetScrollTop = function (el, y, h, direction) {
  const children = el.children;
  let lastY = 0;
  for (const x of children) {
    let offset = x.offsetTop;
    if (x.offsetParent !== el) {
      offset -= el.offsetTop;
    }
    if (offset >= y) {
      if (direction < 0) {
        return lastY;
      } else if (offset !== y) {
        return offset;
      }
    }
    lastY = offset;
  }
  return lastY;
};
function Empty() {
  const gotoNextSlide = (ev) => {
    gotoSlide(ev, 1);
  };
  const gotoPrevSlide = (ev) => {
    gotoSlide(ev, -1);
  };
  const handlers = useSwipeable({
    onSwipedUp: gotoNextSlide,
    onSwipedDown: gotoPrevSlide,
    trackMouse: true,
  });
  const ref = useRef(null);
  const setScroll = useAnimatedScroll();
  const gotoSlide = (ev, direction) => {
    const element =
      ev.target === document
        ? document.scrollingElement || document.documentElement
        : ev.target;
    if (!element) return;
    const scrollTop = element.scrollTop;
    const scrollHeight = element.scrollHeight;
    const clientHeight = element.clientHeight;

    const destScroll = Math.max(
      0,
      Math.min(
        scrollHeight - clientHeight,
        getTargetScrollTop(element, scrollTop, clientHeight, direction)
      )
    );
    setScroll({ element, targetScroll: destScroll });
  };

  const refPassThrough = (el) => {
    ref.current = el;
    handlers.ref(el);
  };

  return (
    <App>
      <div
        {...handlers}
        ref={refPassThrough}
        className="swipeContainer"
        style={{ backgroundColor: "white" }}
      ></div>
    </App>
  );
}

export default Empty;
