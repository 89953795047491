import React from "react";
import App from "./App/App";
import users from "../Images/users.png";
import drivers from "../Images/drivers.png";

const Support = () => {
  const [state, setState] = React.useState(0);
  return (
    <App>
      <div
        class="d-flex justify-space-around"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <div>
          <img src={users} alt="users" style={{ width: 400, height: 400 }} />
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p class="support" onClick={() => setState(1)}>
              Rider not available around me, can i still book a rider?
            </p>
            {state === 1 ? (
              <div class="dropDown">
                <p class="dropDownText">
                  Yes, you can click on the <emp>Continue</emp> button follow
                  the instructions and when the app is searching for a rider,
                  you can click on the{" "}
                  <emp>
                    Request rider, a call will be made to the customer care,
                    which will direct a rider to your end.
                  </emp>
                </p>
              </div>
            ) : null}
            <p class="support" onClick={() => setState(2)}>
              I have issues with my wallet balance
            </p>
            {state === 2 ? (
              <div class="dropDown">
                <p class="dropDownText">
                  Your wallet amount will be always NGN0, except you were
                  debited, if you paid before delivery. The balance will be
                  reflected on your wallet balance, which you can use in your
                  other deliveries. You cannot redraw your wallet balance. For
                  more info, contact us by sending us a mail or talking to our
                  customer care representative.
                </p>
              </div>
            ) : null}
            <p class="support" onClick={() => setState(3)}>
              Verification email did not arrive
            </p>
            {state === 3 ? (
              <div class="dropDown">
                <p class="dropDownText">
                  If you did not see your verification email in your inbox, it
                  is most likely be sitting on your spam folder or junk folder.
                  You can move it to your inbox folder to start receiving it in
                  your inbox folder.
                </p>
              </div>
            ) : null}
          </div>
        </div>
        <div>
          <img
            src={drivers}
            alt="drivers"
            style={{ width: 400, height: 400 }}
          />
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p class="support" onClick={() => setState(4)}>
              My driver account is suspended
            </p>
            {state === 4 ? (
              <div class="dropDown">
                <p class="dropDownText">
                  If your account is suspended, series of report must have been
                  made about you or you must have rejected so many rides or a
                  malicious activity was suspected. send us a mail or call our
                  customer care representative for investigation and if
                  re-opening of the account
                </p>
              </div>
            ) : null}
            <p class="support" onClick={() => setState(5)}>
              Sender did not pay or paid less
            </p>
            {state === 5 ? (
              <div class="dropDown">
                <p class="dropDownText">
                  If a sender pay less or did not pay at all on delivery, you
                  can call our customer care with the details of the trip, we
                  will resolve the issue.
                </p>
              </div>
            ) : null}
            <p class="support" onClick={() => setState(6)}>
              I have issues with my wallet balance
            </p>
            {state === 6 ? (
              <div class="dropDown">
                <p class="dropDownText">
                  Your wallet amount will be always NGN0, except you were
                  debited, if you paid before delivery. The balance will be
                  reflected on your wallet balance, which you can use in your
                  other deliveries. You cannot redraw your wallet balance. For
                  more info, contact us by sending us a mail or talking to our
                  customer care representative.
                </p>
              </div>
            ) : null}
            <p class="support" onClick={() => setState(7)}>
              Verification email did not arrive
            </p>
            {state === 7 ? (
              <div class="dropDown">
                <p class="dropDownText">
                  If you did not see your verification email in your inbox, it
                  is most likely be sitting on your spam folder or junk folder.
                  You can move it to your inbox folder to start receiving it in
                  your inbox folder.
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        <p class="moreInfo">
          For more enquiries or support, send us a mail or call us
        </p>
        <button class="emailButton">
          <a
            href="mailto:errandbossltd@gmail.com"
            alt="call us"
            class="emailText"
          >
            Send us a mail
          </a>
        </button>
        <button class="callButton">
          <a href="tel:+2349155464042" alt="call us" class="callText">
            Call us
          </a>
        </button>
      </div>
    </App>
  );
};

export default Support;
