import {
  Box,
  Card,
  CardContent,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";

const reviews = [
  {
    text: "Perfect work, never believed you finished this before deadline. The design beats my imagination and apps works flawlessly for my staff. Thank you.",
    authorName: "Nurein Etamesor Junior.",
    authorDescription:
      "C.E.O, The thelmes event  center, Sapele Rd, Edo State, Nigeria.",
  },
  {
    text: "Good morning... You are the best!! Just know I completely trust you, the fact you detected flaws and trying to fix it without demanding extra funds really says alot!!! I am truly grateful🙏. Pls take your time to do your thing and let me know if you need anything God bless you sir.😇",
    authorName: "Miss Abieyuwa .S. Balogun.",
    authorDescription: "United Arab Emirates, Dubia.",
  },
  {
    text: "Wow!!! This app you created, manages state in such a way that the exact information sent to me in the app is what i am seeing on the website without no delay at all. It also works with minimal data usage. Thank you, I will keep on doing business with you, Rexdreams",
    authorName: "Kenedict Samuel.",
    authorDescription: "United Kingdom.",
  },
  {
    text: "Excellent work, REXDREAMS!!! I dope my hat for you.",
    authorName: "Balogun Joy.",
    authorDescription: "C.E.O, Crypto Chain, Abuja, Nigeria.",
  },
];
const useStyles = makeStyles((theme) => {
  return {
    reviewCard: {
      width: "400px",
      maxWidth: "70vw",
      margin: theme.spacing(2),
    },
    grid: {
      display: "flex",
      margin: theme.spacing(4),
      justifyContent: "flex-end",
      flexWrap: "wrap",
      [theme.breakpoints.down("xs")]: {
        alignItems: "center",
      },
    },
  };
});
const ReviewCard = function ({ text, authorName, authorDescription }) {
  const classes = useStyles();
  return (
    <Card className={classes.reviewCard}>
      <CardContent>
        <Typography gutterBottom paragraph>{`"${text}"`}</Typography>
        <Typography align="right" variant="subtitle2">
          {authorName}
        </Typography>
        <Typography align="right" variant="subtitle2">
          {authorDescription}
        </Typography>
      </CardContent>
    </Card>
  );
};
export default function FourthPage() {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <Box p={4} bgcolor={theme.palette.background.contrast}>
      <Typography variant="h5">What do our customers say?</Typography>
      <div className={classes.grid}>
        {reviews.map((e, i) => (
          <ReviewCard {...e} key={`${i}`} />
        ))}
      </div>
    </Box>
  );
}
