import { Box, Typography, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "../Components/SetupAos";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import useBreakpoint from "../Helpers/useBreakpoint";
import ceo from "../Images/us/ceo.jpeg";
import pro from "../Images/us/pro.jpeg";
import cto from "../Images/us/cto.jpg";

const useStyles = makeStyles((theme) => {
  return {
    indented: {
      textIndent: theme.spacing(4),
    },
  };
});

const articles = [
  {
    header: " Akpoyibo Fortune Rex",
    animation: ceo,
    preview: {
      title: `Chief Executive Officer, Founder`,
      contact: ``,
    },
  },
  {
    header: "Erhiagboje Kevwe Faith",
    animation: pro,
    preview: {
      title: `Public Interfacing Officer`,
      contact: `+2348182895803`,
    },
  },
  {
    header: "Heather .G. Rose",
    animation: cto,
    preview: {
      title: `Chief Technical Officer`,
      contact: ``,
    },
  },
];

const Article = ({ header, preview, animation, link, left }) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useBreakpoint();
  return (
    <Box
      display="flex"
      flexDirection={left ? "row" : "row-reverse"}
      flexWrap="wrap"
      justifyContent="center"
      alignItems="center"
      style={{
        marginBottom: "32px",
      }}
    >
      <Box
        display="block"
        data-aos="fade"
        data-aos-duration={600}
        style={{
          maxWidth: "75%",
          width: "300px",
          flexGrow: 1,
          margin: "0 25px",
        }}
      >
        <img
          src={animation}
          style={{
            width: "100%",
            height: "auto",
            borderRadius: "50%",
          }}
          alt="Public"
        />
      </Box>
      <Box
        display="block"
        data-aos={left ? "fade-left" : "fade-right"}
        data-aos-duration={600}
        style={{
          width: "50%",
          minWidth: isMobile ? "70vw" : "360px",
          flexGrow: 1,
        }}
      >
        <Typography
          style={{
            marginBottom: theme.spacing(2) + "px",
            fontWeight: "bold",
          }}
          variant={isMobile ? "h5" : "h4"}
        >
          {header}
        </Typography>
        <Typography paragraph className={classes.indented}>
          {preview.title}
          <br />
          {preview.contact}
          {link ? <Link href={link}>...Read more</Link> : ""}
        </Typography>
      </Box>
    </Box>
  );
};

export default function Us({ className }) {
  return (
    <Box className={className} p={4}>
      {articles.map((e, i) => (
        <Article key={`${i}${i}`} {...e} left={i % 2 === 0} />
      ))}
    </Box>
  );
}
