import { auth } from "./Init";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  confirmPasswordReset,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
} from "firebase/auth";
import { createSubscription } from "./ActionCreators";
import Swal from "sweetalert2";
import {
  clearCachedUserData,
  getCachedUserData,
  cacheUserData,
  updateUserData,
} from "./UserData";

export let serverError = [];

export const signUpEmail = async ({ email, password }) => {
  try {
    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: "https://rexdreams.com/",
      // This must be true.
      handleCodeInApp: true,
      // iOS: {
      //   bundleId: "com.example.ios",
      // },
      // android: {
      //   packageName: "com.example.android",
      //   installApp: true,
      //   minimumVersion: "12",
      // },
      // dynamicLinkDomain: "example.page.link",
    };
    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    cacheUserData(email);
    await window.localStorage.setItem('password',password);
    Swal.fire({
      position: 'top-start',
      icon: "success",
      title: "An email has been sent to you, continue registration from there",
    });
  } catch (error) {
    console.log(error);
  }
};
export const confirmEmail = async () => {
  try {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.

      let emailAuth = await getCachedUserData();
      if (!emailAuth) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated emailAuth again. For example:
        emailAuth = window.prompt("Please provide your email for confirmation");
      }

      // The client SDK will parse the code from the link for you.
      let password = await window.localStorage.getItem("password");

      let result = await createUserWithEmailAndPassword(
        auth,
        emailAuth,
        password
      );

      // You can access the new user via result.user
      // Additional user info profile not available via:
      // if (result.additionalUserInfo.profile == null) {
      //   // You can check if the user is new or existing:
      //   return result.additionalUserInfo.isNewUser;
      // }
      // Clear email from storage.
      clearCachedUserData();

      return result.user;
    }
  } catch (error) {
    // Some error occurred, you can inspect the code: error.code
    // Common errors could be invalid email and invalid or expired OTPs.
    console.log(error);
  }
  // console.log("url:", url);

  // let url = await uploadFileAndGetUrl(photoURL, auth.currentUser);
  // console.log("url:", url);

  // await updateProfile(auth.currentUser, {
  //   photoURL: "url",
  //   displayName,
  //   phoneNumber,
  // });
  // .catch(handleError);
};
export const logInEmail = ({ email, password }) => {
  return signInWithEmailAndPassword(auth, email, password).then(
    (userCredential) => {
      return userCredential.user;
    }
  );
  // .catch(handleError);
};
export const signUpGoogle = ({ useRedirect }) => {
  const provider = new GoogleAuthProvider();

  if (useRedirect) return signInWithRedirect(auth, provider);
  else
    return signInWithPopup(auth, provider).then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      return credential.user;
      // ...
    });
  // .catch(handleError);
};
/**@returns {import("firebase/auth").User} */
export const [useUser, onUserChanged] = createSubscription(function (setUser) {
  return onAuthStateChanged(
    auth,
    (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        setUser(user);
        // ...
      } else {
        // User is signed out
        // ...
        setUser(null);
      }
    },
    function () {
      //Possible reason for recurrent signing out
      setUser(null);
    }
  );
});

export const logOut = () => {
  return signOut(auth).then(() => {
    // Sign-out successful.
  });
  // .catch(handleError);
};

export const resetPassword = (email) => {
  return sendPasswordResetEmail(auth, email, {
    url: window.location.href,
  }).then(() => {
    // Password reset email sent!
    // ..
  });
};

export async function finishPasswordReset(oobCode, newPassword) {
  return confirmPasswordReset(auth, oobCode, newPassword);
}
