/**
 * For the messages, we use a simple design that stores all messages in a single collection.
 */

import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from "@firebase/firestore";
import { useMemo } from "react";
import { decryptData, encryptData } from "../Encrypt";
import {
  addFirestore,
  getDataFromSnapshot,
  createSubscription,
  uploadFileAndGetUrl,
} from "./ActionCreators";
import { onUserChanged } from "./Auth";
import { firestore } from "./Init";

export const MESSAGE_SENDING = 1;
export const MESSAGE_SENT = 2;
export const MESSAGE_RECEIVED = 3;
export const MESSAGE_SEEN = 4;
const MESSAGES_COLLECTION = "messages";
const ADMIN = {
  uid: "0",
  displayName: "Rexdreams",
};
export async function getSentMessages({ user }) {
  return getDataFromSnapshot(
    await getDocs(
      query(
        collection(firestore, MESSAGES_COLLECTION),
        where("senderId", "==", user.uid)
      )
    )
  );
}
export async function getReceivedMessages({ user }) {
  return getDataFromSnapshot(
    await getDocs(
      query(
        collection(firestore, MESSAGES_COLLECTION),
        where("receiverId", "==", user.uid)
      )
    )
  );
}
const createUserSubscription = function (createQuery) {
  return createSubscription(function (setData) {
    let unsubscribeToSnapshot;
    const unsuscribeToUserChanges = onUserChanged(function (user) {
      setData(undefined);
      if (unsubscribeToSnapshot) unsubscribeToSnapshot();
      if (user) {
        unsubscribeToSnapshot = onSnapshot(
          createQuery(user),
          function (snapshot) {
            const data = getDataFromSnapshot(snapshot);
            data.forEach(function (message) {
              message.status = decryptData(message.status);
            });
            setData(data);
          }
        );
      } else unsubscribeToSnapshot = null;
    });
    return function () {
      unsuscribeToUserChanges();
      if (unsubscribeToSnapshot) {
        unsubscribeToSnapshot();
      }
    };
  });
};
export async function getReceiverName() {
  return ADMIN.displayName;
}
export const [useSentMessages] = createUserSubscription(function (user) {
  return query(
    collection(firestore, MESSAGES_COLLECTION),
    where("senderId", "==", user.uid)
  );
});

export const [useReceivedMessages] = createUserSubscription(function (user) {
  return query(
    collection(firestore, MESSAGES_COLLECTION),
    where("receiverId", "==", user.uid)
  );
});
/**
 * @typedef {string} MessageId
 * @typedef {{
 *  senderId: string,
 *  receiverId: string,
 *  body:{
 *    content:string,
 *    title: string,
 *    timeCreated: number,
 *    replyingTo: MessageId,
 *    status: string
 *  }
 * }} Message
 */

export async function sendMessage({
  sender,
  receiver = ADMIN,
  title,
  content,
  image,
  timeCreated = new Date().getTime(),
  replyingTo,
}) {
  let imageUrl;
  console.log(image);

  if (image) {
    const id = "IMG-" + timeCreated + "-" + receiver.uid;
    imageUrl = await uploadFileAndGetUrl(image, id);
  }
  await addFirestore(MESSAGES_COLLECTION, {
    senderId: sender.uid,
    receiverId: receiver.uid,
    status: encryptData(MESSAGE_SENT),
    body: {
      content,
      title,
      imageUrl,
      timeCreated,
      replyingTo,
    },
  });
}

export function useUnreadMessages() {
  const messages = useReceivedMessages();
  const unread = useMemo(
    function () {
      const unread = new Map();
      if (messages) {
        for (let [id, message] of messages) {
          if (message.body.status !== MESSAGE_SEEN) {
            unread.set(id, message);
          }
        }
        return unread;
      }
    },
    [messages]
  );
  return unread;
}
