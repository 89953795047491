import React from "react";
import Avatar from "@material-ui/core/Avatar";
import "./Message.scss";
import logo from "../../Images/logo.png";

export const MessageLeft = ({
  message = "",
  timestamp = "",
  altName = "",
  displayName = "",
  photoURL = "",
  imageUrl,
  isFollow,
}) => {
  return (
    <>
      <div
        style={{
          display: "flex",
        }}
      >
        {photoURL ? (
          <Avatar alt={displayName} src={photoURL}></Avatar>
        ) : (
          <Avatar alt={"Rexdreams"} src={logo}></Avatar>
        )}
        <div class="Chat-msg">
          <div class={`Chat-bubble ${isFollow ? "Chat-follow" : ""}`}>
            <div class="Chat-txt">
              <span class="Chat-name">
                {displayName}
                <span>{` ${altName}`}</span>
              </span>
              {imageUrl ? <img src={imageUrl} alt="" /> : undefined}
              <span class="Chat-timestamp">{timestamp}</span>
              <p class="Chat-message">{message}</p>
            </div>
            {isFollow ? undefined : <div class="Chat-bubble-arrow"></div>}
          </div>
        </div>
      </div>
    </>
  );
};
export const MessageRight = ({ message, timestamp, imageUrl, isFollow }) => {
  return (
    <div class="Chat-msg">
      <div class={`Chat-bubble ${isFollow ? "Chat-altfollow" : "Chat-alt"}`}>
        <div class="Chat-txt">
          {imageUrl ? <img src={imageUrl} alt="" /> : undefined}
          <span class="Chat-timestamp">{timestamp}</span>
          <p class="Chat-message">{message}</p>
        </div>
        {isFollow ? undefined : <div class="Chat-bubble-arrow Chat-alt"></div>}
      </div>
    </div>
  );
};
