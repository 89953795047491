import * as CryptoJS from "crypto-js";
const iv = CryptoJS.enc.Base64.parse("obiyo.com61499ceorexdreams");
const mode = CryptoJS.mode.CBC;
const padding = CryptoJS.pad.Pkcs7;
const key = CryptoJS.SHA512(
  '?*&^%$#%^&*(@*#&$^%#^&*(#*&$^(*&$%#^&*((*&^$%#%^#$%^&*($$*&^%#^&#&##&?*^&%#%^##%^<??+""~obiyo&^**@%@&@**@.^FSJGSJW^%#(@com&*#^&#)@{}{{^$(%^&$^1499>?>?>??@$$@ceo!!*#^%#($*<>?@#!^&!~~%$#%^&@#$#^$*&^%$&##$@&*#&#&##$%^%$#@E#@$@@$#%#~~~rex#%#$@@d@$!%!&$@r#4563784a^*$%#&&#m$%^&*#ss74433'
);
const OBJECT_HEADER = "\x00OBJ1ECT\x00";

/**@param {(string|object)} data*/
export const encryptData = (data) => {
  let encryptedString;
  if (!data) return data;
  if (typeof data === "string") {
    data = data.slice();
    encryptedString = CryptoJS.AES.encrypt(data, key, { iv, mode, padding });
  } else if (data.body !== undefined) {
    return { ...data, body: encryptData(data.body) };
  } else {
    encryptedString = CryptoJS.AES.encrypt(
      OBJECT_HEADER + JSON.stringify(data),
      key,
      {
        iv,
        mode,
        padding,
      }
    );
  }
  return { secureData: encryptedString.toString() };
};

/**
 * @typedef {{secureData:string}} SecuredData
 * @param data {(SecuredData|{body:SecuredData,[string]:any})} */
export const decryptData = (data) => {
  if (!data) return data;
  if (data.body !== undefined) {
    return { ...data, body: decryptData(data.body) };
  }
  //Unencrypted data
  if (data.secureData === undefined) {
    return data;
  }

  let decryptedString = CryptoJS.AES.decrypt(data.secureData, key, {
    iv,
    mode,
    padding,
  });
  let result = decryptedString.toString(CryptoJS.enc.Utf8);
  if (result.startsWith(OBJECT_HEADER)) {
    result = JSON.parse(result.substring(OBJECT_HEADER.length));
  }
  return result;
};
export const hash = (password) => {
  let pwd = password + key;
  let hashedPwd = CryptoJS.SHA512(pwd);
  return hashedPwd;
};
