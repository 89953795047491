import { useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { NavLink } from "react-router-dom";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import InfoIcon from "@material-ui/icons/Info";

import MailIcon from "@material-ui/icons/Mail";
import MoreIcon from "@material-ui/icons/MoreVert";
import HomeIcon from "@material-ui/icons/Home";
import SignupCard from "./SignupCard";
import logo from "../../Images/logo.png";
import { ButtonBase, Typography, useScrollTrigger } from "@material-ui/core";
import { useUser } from "../../Logic/Firebase/Auth";
import AccountProfile from "../../Components/AccountProfile";
import useLinkHandler from "../../Helpers/handleLink.js";
import { useUnreadMessages } from "../../Logic/Firebase/Messages";
import { useLocation } from "react-router-dom";
import MessageCard from "./MessageCard";
const useStyles = makeStyles((theme: Theme) => ({
  grow: {
    flexGrow: 1,
    // display: "none",
  },
  bg: {
    background: theme.palette.background.default,
  },
  menu: {
    "& .MuiMenuItem-root": {
      paddingLeft: theme.spacing(0),
      minWidth: "200px",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  menuItemBottomRow: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0),
    paddingRight: theme.spacing(1),
    justifyContent: "space-around",
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.searchBar.default,
    "&:hover": {
      backgroundColor: theme.palette.searchBar.hover,
    },
    marginRight: theme.spacing(3),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const MobileMenu = ({
  anchorEl,
  handleSignupMenuOpen,
  onClose,
  id,
  handleMessagesOpen,
}) => {
  const classes = useStyles();
  const unreadMessages = useUnreadMessages()?.size;

  const user = useUser();
  const isMobileMenuOpen = Boolean(anchorEl);
  const location = useLocation();
  const HandleLink = useLinkHandler();
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={id}
      keepMounted
      className={classes.menu}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={onClose}
    >
      {
        <div>
          {user === null && (
            <>
              <MenuItem>
                <IconButton aria-label="Home">
                  <HomeIcon />
                </IconButton>
                <NavLink
                  to="/Home"
                  activeStyle={{
                    color: "purple",
                    fontWeight: "bold",
                    fontSize: 10,
                    textDecoration: "none",
                  }}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    textDecoration: "none",
                  }}
                  aria-label="Home"
                  className="titleHome"
                >
                  HOME
                </NavLink>
              </MenuItem>
              <MenuItem
                divider={location.pathname !== "/User"}
                // onClick={HandleLink("/About")}
              >
                <IconButton aria-label="About">
                  <InfoIcon />
                </IconButton>
                <NavLink
                  to="/About"
                  activeStyle={{
                    color: "purple",
                    fontWeight: "bold",
                    fontSize: 10,
                    textDecoration: "none",
                  }}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    textDecoration: "none",
                  }}
                  aria-label="About us"
                  className="titleHome"
                >
                  ABOUT
                </NavLink>
              </MenuItem>
            </>
          )}
          {location.pathname === "/User" ? undefined : (
            <div className={classes.menuItemBottomRow}>
              <IconButton
                onClick={handleSignupMenuOpen}
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
              >
                <AccountProfile />
              </IconButton>

              {user ? (
                <IconButton
                  onClick={handleMessagesOpen}
                  aria-label={`show {unreadMessages} new mails`}
                  color="inherit"
                >
                  <Badge badgeContent={unreadMessages} color="secondary">
                    <MailIcon />
                  </Badge>
                </IconButton>
              ) : undefined}
            </div>
          )}
        </div>
      }
    </Menu>
  );
};
const Header = function ({ window }) {
  const classes = useStyles();
  const unreadMessages = useUnreadMessages()?.size;

  const [signupAnchorEl, setSignupMenuAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [messagesAnchorEl, setMessagesAnchorEl] = useState(null);
  const HandleLink = useLinkHandler();
  const [focused, setFocused] = useState(false);
  const setBlurred = () => {
    setFocused(false);
  };
  const handleSignupMenuOpen = (event) => {
    setMessagesAnchorEl(null);
    if (mobileMoreAnchorEl) {
      setSignupMenuAnchorEl(mobileMoreAnchorEl);
      setMobileMoreAnchorEl(null);
    } else setSignupMenuAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMessagesClose = () => {
    setMessagesAnchorEl(null);
  };

  const handleSignupMenuClose = () => {
    setSignupMenuAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleMessagesOpen = (event) => {
    setMessagesAnchorEl(null);
    if (mobileMoreAnchorEl) {
      setMessagesAnchorEl(mobileMoreAnchorEl);
      setMobileMoreAnchorEl(null);
    } else setMessagesAnchorEl(event.currentTarget);
  };
  const handleMobileMenuOpen = (event) => {
    setSignupMenuAnchorEl(null);
    setMessagesAnchorEl(null);
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const signupMenuId = "primary-search-account-menu";
  const messagesId = "primary-mesages-menu";

  const mobileMenuId = "primary-mobile-menu";

  // const [searchModalShown, setShowSearchModal] = useState(false);
  const user = useUser();
  const scrolleddown = useScrollTrigger({
    disableHysteresis: true,
    target: window ? window() : undefined,
  });
  return (
    <div>
      <AppBar
        className={classes.bg}
        color="inherit"
        elevation={scrolleddown ? 4 : 0}
        position="fixed"
      >
        <Toolbar>
          <ButtonBase
            onClick={HandleLink("/Home")}
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <img alt="logo" src={logo} height="50px" width="50px" />
          </ButtonBase>
          <Typography
            className="App-brandname"
            style={{
              fontSize: "24px",
              display: "block",
              height: "30px",
              lineHeight: "36px",
              color: "gold",
            }}
            variant="caption"
          >
            REXDREAMS
          </Typography>

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {user === null && (
              <div className="d-flex">
                <div className="titleMenu">
                  <NavLink
                    to="/Home"
                    activeStyle={{
                      color: "purple",
                      fontWeight: "bold",
                      fontSize: 10,
                      textDecoration: "none",
                    }}
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      textDecoration: "none",
                    }}
                    aria-label="Home"
                    className="titleHome"
                  >
                    HOME
                  </NavLink>
                  <NavLink
                    to="/About"
                    activeStyle={{
                      color: "purple",
                      fontWeight: "bold",
                      fontSize: 10,
                      textDecoration: "none",
                    }}
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      textDecoration: "none",
                    }}
                    aria-label="About us"
                    className="titleHome"
                  >
                    ABOUT
                  </NavLink>
                </div>
              </div>
            )}{" "}
            {user ? (
              <IconButton
                onClick={handleMessagesOpen}
                aria-label={`show {unreadMessages} new mails`}
                color="inherit"
              >
                <Badge badgeContent={unreadMessages} color="secondary">
                  <MailIcon />
                </Badge>
              </IconButton>
            ) : undefined}
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={signupMenuId}
              aria-haspopup="true"
              onClick={handleSignupMenuOpen}
              color="inherit"
            >
              <AccountProfile />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <MessageCard
        anchorEl={messagesAnchorEl}
        id={messagesId}
        onClose={handleMessagesClose}
      />
      <MobileMenu
        anchorEl={mobileMoreAnchorEl}
        id={mobileMenuId}
        onClose={handleMobileMenuClose}
        handleSignupMenuOpen={handleSignupMenuOpen}
        handleMessagesOpen={handleMessagesOpen}
      />
      <SignupCard
        anchorEl={signupAnchorEl}
        id={signupMenuId}
        onClose={handleSignupMenuClose}
      />
    </div>
  );
};
export default Header;
